import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette: { black, secondary, white } }) => ({
  avatar: {
    border: `2px solid ${secondary.main}`,
    backgroundColor: white.main,
    color: black.light,
    width: ({ size }) => size,
    height: ({ size }) => size,
  },

  button: {
    borderRadius: 999,
    opacity: ({ disabled }) => (disabled ? 0.5 : 1),
  },
}));

export default useStyles;
