import { useQuery } from '@apollo/react-hooks';
import { getMeQuery } from '../../queries/user';

export default (skip) => {
  const { loading, data, refetch } = useQuery(getMeQuery, {
    fetchPolicy: 'no-cache',
    skip,
  });
console.log(`data`,data)
  return { loading, data: data && data.me, refetch };
};
