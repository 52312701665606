import gql from 'graphql-tag';
import { notificationFragment } from '../fragments/notifications';

const getNotificationsQuery = gql`
  query Notifications($first: Int, $after: String, $filter: DefaultFilterInput) {
    me {
      notifications(
        first: $first,
        after: $after,
        filter: $filter,
      ) {
        edges {
          node { ...MainNotificationData }
        }
      }
    }
  }
  ${notificationFragment}
`;

// eslint-disable-next-line import/prefer-default-export
export { getNotificationsQuery };
