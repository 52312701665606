import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Input from '../Input';
import PrivacyCheckbox from '../PrivacyCheckbox';

const useStyles = makeStyles(({ spacing }) => ({
  privacyContainer: {
    marginTop: spacing(4),
    marginBottom: spacing(4),
    lineHeight: '16px',
  },
}));

const Form = ({
  fields,
  register,
  loading,
  errors,
  onChange,
  privacyRequiredFields,
}) => {
  const { t } = useTranslation('newsletter');
  const { privacyContainer } = useStyles();

  const getErrorField = (field) => {
    const foundFieldError = Object.keys(errors).find((fieldName) => fieldName === field);

    if (foundFieldError) {
      return errors[foundFieldError].message || true;
    }

    return false;
  };

  return (
    <Grid container justify="center" spacing={1}>
      {fields.map(({
        label,
        type,
        required,
        getRegister = () => {},
        xs,
        sm,
        multiline,
      }) => (
        <Grid key={label} item xs={xs} sm={sm}>
          <Input
            name={label}
            label={t(`common:${label}`)}
            type={type}
            onChange={onChange}
            disabled={loading}
            multiline={multiline}
            required={required}
            hasError={getErrorField(label)}
            ref={register(getRegister())}
          />
        </Grid>
      ))}

      <div className={privacyContainer}>
        <PrivacyCheckbox
          register={register}
          disabled={loading}
          style={{ lineHeight: '16px' }}
          requiredFields={privacyRequiredFields}
        />
      </div>
    </Grid>
  );
};

Form.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  register: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.arrayOf(),
  onChange: PropTypes.func,
  privacyRequiredFields: PropTypes.arrayOf(PropTypes.bool),
};

Form.defaultProps = {
  loading: false,
  errors: [],
  onChange: undefined,
  privacyRequiredFields: [true, false],
};

export default Form;
