const spacing = 8;

const cardSizes = {
  xl: {
    width: 300,
    height: 446,
  },
  lg: {
    width: 270,
  },
  md: {
    width: 200,
    height: 300,
  },
  xs: {
    width: 152,
    height: 250,
  },
};

const lightPrimaryColor = { hex: '#F2F8F8', rgb: [242, 248, 248] };
const mediumPrimaryColor = { hex: '#83C7B5', rgb: [131, 199, 181] };
const mediumDarkPrimaryColor = { hex: '#0EAC8F', rgb: [14, 172, 143] };
const primaryColor = { hex: '#2D9671', rgb: [45, 150, 113] };
const lightSecondaryColor = { hex: '#FFF9EE', rgb: [251, 243, 218] };
const secondaryColor = { hex: '#F6BE40', rgb: [246, 190, 64] };
const lightGrey = { hex: '#FAFAFA', rgb: [251, 251, 251] };
const mediumGrey = { hex: '#E8E8E8', rgb: [232, 232, 232] };
const darkGrey = { hex: '#A8A8A7', rgb: [54, 54, 54] };
const white = { hex: '#ffffff', rgb: [255, 255, 255] };
const lightBlack = { hex: '#363636', rgb: [168, 168, 167] };
const black = { hex: '#000000', rgb: [0, 0, 0] };
const mediumFacebook = { hex: '#175EE7', rgb: [23, 92, 231] };
const darkFacebook = { hex: '#154FAC', rgb: [21, 79, 172] };
const mediumGoogle = { hex: '#E74933', rgb: [231, 73, 51] };
const darkGoogle = { hex: '#cd2d18', rgb: [205, 45, 24] };
const mediumRed = { hex: '#F94B4B', rgb: [249, 75, 75] };
const mediumGreen = { hex: '#98c666', rgb: [152, 198, 102] };
const mediumYellow = { hex: '#FFC936', rgb: [255, 201, 54] };

export {
  black,
  cardSizes,
  darkFacebook,
  darkGoogle,
  darkGrey,
  lightGrey,
  lightBlack,
  lightPrimaryColor,
  lightSecondaryColor,
  mediumFacebook,
  mediumGoogle,
  mediumGreen,
  mediumGrey,
  mediumDarkPrimaryColor,
  mediumPrimaryColor,
  mediumRed,
  mediumYellow,
  primaryColor,
  secondaryColor,
  spacing,
  white,
};
