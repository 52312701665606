import React from 'react';
import PropTypes from 'prop-types';
import googlePlayBadge from '../../assets/google-play-badge.svg';
import appleStoreBadge from '../../assets/apple-store-badge.svg';

const badges = {
  ios: {
    icon: appleStoreBadge,
    url: 'https://apps.apple.com/it/app/libraway/id1561825542',
  },
  android: {
    icon: googlePlayBadge,
    url: 'https://play.google.com/store/apps/details?id=com.libraway.app',
  },
};

const MobileStoreBadge = ({ store }) => (
  <a href={badges[store].url} target="_blank" rel="noopener noreferrer">
    <img
      src={badges[store].icon}
      height={40}
      alt="mobile app store"
    />
  </a>
);

MobileStoreBadge.propTypes = {
  store: PropTypes.oneOf(['ios', 'android']).isRequired,
};

export default MobileStoreBadge;
