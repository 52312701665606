import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints, spacing, palette: { black } }) => ({
  container: {
    overflowX: 'hidden',
    padding: ({ noPadding }) => (noPadding ? 0 : spacing(0, 3)),
    borderRadius: 30,
    position: 'relative',
    minWidth: ({ large, minWidth }) => {
      if (minWidth) return minWidth;
      return large ? 800 : 500;
    },
    [breakpoints.down('sm')]: {
      margin: spacing(1),
      minWidth: () => '70%',
    },
    [breakpoints.down('xs')]: {
      minWidth: 'auto',
      width: '100vw',
      maxHeight: '100%',
      borderRadius: '30px 30px 0 0',
      margin: 0,
      marginTop: spacing(2),
      padding: () => 0,
    },
  },

  scrollPaper: {
    [breakpoints.down('xs')]: {
      alignItems: 'flex-end',
    },
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(2, 0),
    textAlign: 'right',
    fontSize: 18,
    minHeight: 60,
    [breakpoints.down('xs')]: {
      padding: spacing(2),
    },
  },

  content: {
    padding: spacing(0, 4, 4, 4),
  },

  closeButton: {
    position: 'absolute',
    top: spacing(2.5),
    right: spacing(2),
    padding: spacing(0, 1),
    borderRadius: 10,
    '& .times-main': {
      stroke: black.dark,
    },
  },
}));

export default useStyles;
