import { styled, makeStyles } from '@material-ui/core/styles';
import MuiAvatar from '@material-ui/core/Avatar';

const FooterContainer = styled('div')(({
  theme: { palette: { white, black }, spacing },
}) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  backgroundColor: black.light,
  paddingTop: spacing(5),
  paddingBottom: spacing(8),
  color: white.main,
}));

const Avatar = styled(MuiAvatar)(({ theme: { spacing } }) => ({
  marginRight: spacing(2),
  '&:last-child': { marginRight: 0 },
}));

const useStyles = makeStyles(({ breakpoints, palette: { primary }, spacing }) => ({
  logoGrid: {
    [breakpoints.down('sm')]: {
      paddingBottom: spacing(5),
    },
  },

  logo: {
    width: 128,
    marginRight: spacing(2),
    alignSelf: 'center',
    [breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },

  noHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  divider: {
    marginTop: spacing(2),
    borderTop: `2px solid ${primary.dark}`,
    paddingTop: spacing(),
  },

  copyright: {
    [breakpoints.down('xs')]: { justifyContent: 'center' },
    [breakpoints.down('md')]: { paddingLeft: spacing(2) },
  },

  terms: {
    [breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },

  columnContainer: {
    [breakpoints.down('xs')]: {
      paddingBottom: spacing(2),
      marginBottom: spacing(2),
      borderBottom: `2px solid ${primary.dark}`,
    },
  },

  linkContainer: {
    [breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },

  buttonDisabled: {
    opacity: 0.5,
  },
}));

export {
  FooterContainer,
  Avatar,
  useStyles,
};
