import { makeStyles } from '@material-ui/core/styles';

const getMargin = (sideProp, spacing) => (props) => {
  const { margin } = props;
  const side = props[sideProp];

  if (margin === null && side === null) return `${spacing(2)}px !important`;
  return `${spacing(side !== null ? side : margin)}px !important`;
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: ({ color }) => (color === 'primary' ? palette[color].dark : palette[color].main),
    marginTop: getMargin('marginTop', spacing),
    marginBottom: getMargin('marginBottom', spacing),
    height: ({ thickness }) => (thickness === 'thin' ? 1 : 3),
  },
}));

export default useStyles;
