const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1025,
    lg: 1280,
    xl: 1474,
  },
};

export default breakpoints;
