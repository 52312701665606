import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette: { common, secondary } }) => ({
  topBannerContainer: {
    zIndex: 1200,
    transition: 'top 150ms ease',
    top: ({ bannerShown }) => (bannerShown ? 0 : -35),
  },

  container: {
    height: 35,
    width: '100%',
    backgroundColor: secondary.light,
    textTransform: 'uppercase',
    fontSize: 14,
  },

  gridContainer: {
    cursor: 'pointer',
    lineHeight: 1,
    position: 'relative',
    height: 35,
    '& * + *': {
      marginLeft: 4,
    },
  },

  infoIconStyle: {
    marginTop: -1,
  },

  timesIconStyle: {
    '& .times-main': {
      stroke: common.black,
    },
  },

  closeContainer: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

export default useStyles;
