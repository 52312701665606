import React, { createContext, useContext } from 'react';
import { childrenPropType } from '../components/propTypes';
import useAppState from '../hooks/useAppState';

const AppContext = createContext([]);

const AppProvider = ({ children }) => {
  const appState = useAppState();

  return (
    <AppContext.Provider value={appState}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

AppProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  AppContext,
  AppProvider,
  useAppContext,
};
