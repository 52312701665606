import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useAppContext } from '../../contexts/AppContext';
import BottomCart from './BottomCart';
import Footer from '../../layout/Footer';
import useStyles from './style';

const Page = ({
  component: Component,
  title,
  showBottomCart,
  bannerShown,
  props,
}) => {
  const { t } = useTranslation('titles');
  const { isWithinWebview } = useAppContext();
  const { pageContainer } = useStyles({ bannerShown });

  return (
    <>
      <div className={pageContainer}>
        <MetaTags>
          <title>{t(title)}</title>
        </MetaTags>

        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />

        {!isWithinWebview && <Footer />}
      </div>
      {showBottomCart ? <BottomCart /> : null}
    </>
  );
};

Page.propTypes = {
  component: PropTypes.func.isRequired,
  title: PropTypes.string,
  showBottomCart: PropTypes.bool,
  bannerShown: PropTypes.bool,
  props: PropTypes.shape(),
};

Page.defaultProps = {
  title: undefined,
  showBottomCart: false,
  bannerShown: false,
  props: {},
};

export default Page;
