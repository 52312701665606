import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const Placeholder = () => {
  const { t } = useTranslation();
  const { palette: { grey } } = useTheme();

  return (
    <div style={{ width: '100%', padding: 50 }}>
      <Typography
        align="center"
        style={{ color: grey.dark }}
      >
        {t('no-notification')}
      </Typography>
    </div>
  );
};

export default Placeholder;
