import React from 'react';
import { styled } from '@material-ui/core/styles';
import Loading from './Loading';


const Container = styled('div')(({ theme: { spacing } }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  minHeight: spacing(54),
}));

const GlobalLoading = () => <Container><Loading /></Container>;

export default GlobalLoading;
