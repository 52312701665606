import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ spacing }) => ({
  headerTitle: {
    fontSize: '27px',
    lineHeight: '32px',
    marginBottom: spacing(2),
  },

  mainContent: {
    display: 'flex',
    flexDirection: 'column',
  },

  feedbackContainer: {
    padding: spacing(4, 2),
  },
}));

export default useStyles;
