/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function Footer({
  footerBgColor,
  btnBgColor,
  btnTxtColor,
  btnTxt,
  messageBoxTxt,
  messageBox,
  inputPlaceholder,
  phoneNo,
}) {
  const [message, setMessage] = useState(messageBoxTxt ? encodeURI(messageBoxTxt) : '');

  return /* #__PURE__ */React.createElement('div', {
    className: 'whatsapp_widget_footer',
    style: {
      backgroundColor: footerBgColor,
    },
  }, messageBox && /* #__PURE__ */React.createElement('div', null, /* #__PURE__ */React.createElement('input', {
    type: 'text',
    onChange: (e) => setMessage(encodeURI(e.target.value)),
    className: 'whatsapp_widget_input',
    placeholder: inputPlaceholder,
    defaultValue: messageBoxTxt,
  })), /* #__PURE__ */React.createElement('a', {
    href: `https://wa.me/${phoneNo}?text=${message}`,
    onClick: (e) => {
      if (!phoneNo) {
        e.preventDefault();
        return console.error('There is no phone number added to this widget!');
      }
      return null;
    },
    target: '_blank',
    className: 'whatsapp_widget_footer_btn',
    rel: 'noreferrer',
    style: {
      color: btnTxtColor,
      backgroundColor: btnBgColor,
    },
  }, btnTxt));
}

Footer.propTypes = {
  footerBgColor: PropTypes.string,
  btnTxtColor: PropTypes.string,
  btnTxt: PropTypes.string,
  btnBgColor: PropTypes.string,
  phoneNo: PropTypes.string,
  messageBox: PropTypes.string,
  messageBoxTxt: PropTypes.string,
  inputPlaceholder: PropTypes.string,
};

Footer.defaultProps = {
  footerBgColor: 'rgb(245, 245, 245)',
  btnTxtColor: 'rgb(245, 245, 245)',
  btnTxt: 'Start chat',
  btnBgColor: 'rgb(79, 206, 93)',
  phoneNo: null,
  messageBox: null,
  messageBoxTxt: null,
  inputPlaceholder: 'Type a message..',
};
