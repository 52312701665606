import React, {
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { librawayStorage } from '../libraries/helpers';
import { childrenPropType } from '../components/propTypes';
import useCurrentMe from '../graphql/hooks/user/useCurrentMe';

const AuthContext = createContext({
  isLoggedIn: undefined,
  login: () => {},
  logout: () => {},
});

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [user, setUser] = useState(() => {
    return undefined;
  });
  const { loading, data: userData } = useCurrentMe();
  /**
   * Listening auth locale state changing and set them in localstorage
   */
console.log(`user`,user)
  useEffect(() => {
    console.log(`userData`,userData)
    if (userData) {
      setUser(userData);
    } else if (!loading) {
      setUser(null);
    }
  }, [userData]);

  /**
   * Exportable methods
   */
  const handleSignIn = (signInData, options) => {
    if (signInData) {
      const { user: responseUser, ...responseAuthentication } = signInData;
      librawayStorage.set('authentication', responseAuthentication);

      setUser(responseUser);

      history.push(options?.redirectTo || '/');
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.clear('authentication');
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: user === undefined ? undefined : user ? true : false,
        handleSignIn,
        logout,
        user: {
          ...user,
          hasPlan: !!user?.plan?.startDate,
          planType: user?.plan?.type,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

AuthProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export { AuthContext, AuthProvider, useAuthContext };
