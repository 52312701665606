import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Widget from 'rasa-webchat';
import useBus from 'use-bus';
import Slide from '@material-ui/core/Slide';
import useOpenModal from '../../hooks/useModalOpen';
import { useAuthContext } from '../../contexts/AuthContext';
import useRegisterChatSession from '../../graphql/hooks/chat/useRegisterChatSession';
import useUnregisterChatSession from '../../graphql/hooks/chat/useUnregisterChatSession';

const { REACT_APP_SOCKET_URL, REACT_APP_SOCKET_PATH } = process.env;

const RasaWidget = () => {
  const rasaRef = useRef(null);
  const userIdRef = useRef(null);
  const history = useHistory();
  const { opened, openModal, closeModal } = useOpenModal();
  const [registerChatSession] = useRegisterChatSession();
  const [unregisterChatSession] = useUnregisterChatSession();

  const { i18n: { language } } = useTranslation();
  const { user } = useAuthContext();

  useBus(
    '@@rasa/addNote',
    ({ payload }) => {
      rasaRef.current.sendMessage(`/interaction_add_note${JSON.stringify(payload)}`);
    },
    [],
  );

  useBus('@@rasa/display', ({ payload }) => (payload ? closeModal() : openModal()));

  const authenticateChat = () => {
    if (userIdRef.current !== user.id) {
      if (user.id) {
        registerChatSession({
          variables: {
            token: rasaRef.current.getSessionId(),
          },
        });
      } else {
        unregisterChatSession({
          variables: {
            token: rasaRef.current.getSessionId(),
          },
        });
      }

      userIdRef.current = user.id;
    }
  };

  useEffect(authenticateChat, [user.id]);

  const handleEvents = ({ action, payload }) => {
    switch (action) {
      case 'goTo': {
        const timestamp = +new Date();
        history.push(`${payload.url}${payload.url.includes('?') ? '&' : '?'}timestamp=${timestamp}`);
        break;
      }

      default:
    }
  };

  return (
    <Slide direction="up" in={opened}>
      <div>
        <Widget
          ref={rasaRef}
          socketUrl={REACT_APP_SOCKET_URL}
          socketPath={REACT_APP_SOCKET_PATH}
          customData={{ language }}
          onSocketEvent={{
            bot_uttered: handleEvents,
            connect: authenticateChat,
            disconnect: (event) => { console.log('disconnect', event); },
          }}
        />
      </div>
    </Slide>
  );
};

export default RasaWidget;
