import gql from 'graphql-tag';

const getMeQuery = gql`
  query Registry {
    me {
      avatar { url },
      email,
      gender,
      id,
      name,
      plan { start_date },
      meta {
        meta
        value
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { getMeQuery };
