import gql from 'graphql-tag';

const readNotificationMutation = gql`
  mutation ReadNotification($id: ID) {
    readNotification(id: $id) {
      id
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { readNotificationMutation };
