import { useTranslation } from 'react-i18next';
import * as dateFnsLocales from 'date-fns/locale';
import {
  format as formatDateFns,
  startOfWeek as startOfWeekDateFns,
  endOfWeek as endOfWeekDateFns,
  formatDistanceToNow as formatDistanceToNowDateFns,
} from 'date-fns';
import { getLocaleDateFnsFormat } from '../libraries/helpers';

const useDateFns = () => {
  const { i18n: { language: currentLanguage } } = useTranslation();
  const locale = dateFnsLocales[getLocaleDateFnsFormat(currentLanguage)];
  const weekStartsOn = 1;

  const format = (date, formatString) => formatDateFns(typeof date === 'string' ? new Date(date) : date, formatString, { locale });
  const startOfWeek = (date) => startOfWeekDateFns(date, { weekStartsOn });
  const endOfWeek = (date) => endOfWeekDateFns(date, { weekStartsOn });
  const formatDistanceToNow = (date, options) => (
    formatDistanceToNowDateFns(date, { locale, ...options })
  );

  return {
    endOfWeek,
    format,
    formatDistanceToNow,
    startOfWeek,
  };
};

export default useDateFns;
