import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { uselessFunction } from '../../../libraries/helpers';
import Dialog from '../../../components/Dialog';
import MobileStoreBadge from '../../../components/MobileStoreBadge';
import mainImage from '../../../assets/popup_scarica_app.png';

const MobileAppDialog = ({ opened, closeModal }) => {
  const { t } = useTranslation('dashboard');

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <Dialog
      open={opened}
      onClose={handleCloseModal}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: t('app-dialog-title') }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="center">
            {t('app-dialog-subtitle')}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <img
            src={mainImage}
            alt="Scarica app"
            style={{ maxWidth: 340 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <MobileStoreBadge store="ios" />
            </Grid>
            <Grid item>
              <MobileStoreBadge store="android" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            align="center"
            style={{ fontSize: 12, lineHeight: '18px' }}
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: t('app-dialog-footer') }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

MobileAppDialog.propTypes = {
  opened: PropTypes.bool,
  closeModal: PropTypes.func,
};

MobileAppDialog.defaultProps = {
  opened: false,
  closeModal: uselessFunction,
};

export default MobileAppDialog;
