import { useState, useEffect } from 'react';

const useMouseLeaveWindow = () => {
  const [mouseLeaveWindow, setMouseLeaveWindow] = useState(false);

  const setMouseLeave = () => { setMouseLeaveWindow(true); };
  const setMouseEnter = () => { setMouseLeaveWindow(false); };

  useEffect(() => {
    document.addEventListener('mouseleave', setMouseLeave);
    document.addEventListener('mouseenter', setMouseEnter);
  }, []);

  return mouseLeaveWindow;
};

export default useMouseLeaveWindow;
