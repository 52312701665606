import i18n from 'i18next';
import Cache from 'i18next-localstorage-cache';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resources from '../locales';

i18n
  .use(Cache)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it', // Forcing it language
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    ns: ['common', 'header', 'footer'],
    defaultNS: 'common',
    interpolation: { escapeValue: false },
    react: {
      bindStore: false,
      bindI18n: 'languageChanged',
    },
  });

export default i18n;
