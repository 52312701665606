import { createMuiTheme } from '@material-ui/core/styles';

import { spacing } from '../common/constants';
import useClasses from './classes';
import breakpoints from './breakpoints';
import typographyEnhancer from './typography';
import palette from './palette';
import getOverrides from './overrides';
import props from './props';

const defaultMuiTheme = createMuiTheme();

const theme = createMuiTheme({
  useClasses,
  breakpoints,
  palette,
  spacing,
  overrides: getOverrides(defaultMuiTheme),
  props,
});

export default typographyEnhancer(theme);
