import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette: { grey }, spacing }) => ({
  titleStyle: {
    lineHeight: 1.6,
    fontSize: '0.8rem',
    fontWeight: 500,
  },

  messageStyle: {
    maxWidth: 380,
    lineHeight: 1.6,
    fontSize: '0.8rem',
  },

  dateStyle: {
    lineHeight: 1.6,
    fontSize: '0.6rem',
  },

  readAllContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: spacing(0, 1),
  },

  readAllButton: {
    color: grey.dark,
    padding: spacing(0.5, 1),
    borderRadius: 5,
  },
}));

export default useStyles;
