import React from 'react';
import PropTypes from 'prop-types';
import DrawerMui from '@material-ui/core/Drawer';
import { childrenPropType } from '../propTypes';
import { uselessFunction } from '../../libraries/helpers';
import useStyles from './style';

const Drawer = ({
  open,
  onClose,
  onScroll,
  children,
}) => (
  <DrawerMui
    open={open}
    anchor="right"
    onClose={onClose}
    classes={useStyles()}
    onScroll={onScroll}
  >
    {children}
  </DrawerMui>
);

Drawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onScroll: PropTypes.func,
  children: childrenPropType.isRequired,
};

Drawer.defaultProps = {
  open: false,
  onClose: uselessFunction,
  onScroll: uselessFunction,
};

export default Drawer;
