import { useReducer, useEffect } from 'react';
import findLast from 'lodash/findLast';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';
import useGetBoxes from '../graphql/hooks/generic/useGetBoxes';
import { useAppContext } from '../contexts/AppContext';
import { callFlutterHandler, librawayStorage } from '../libraries/helpers';
import { pushToDataLayer } from '../libraries/analyticsHelpers';

const localPushToDataLayer = ({ action, item }) => {
  const {
    id, quantity = 1, title, price, unitPrice, dimension,
  } = item;
  const actionEvents = {
    add: 'addToCart',
    remove: 'removeFromCart',
  };

  pushToDataLayer({
    event: actionEvents[action],
    ecommerce: {
      currencyCode: 'EUR',
      [action]: {
        products: [
          {
            name: title,
            id,
            quantity,
            price: price || unitPrice,
            brand: 'Libraway',
            variant: dimension,
          },
        ],
      },
    },
  });
};

const DEFAULT_STATE = {
  type: 'BOX',
  items: [],
  coupon: null,
  orderId: null,
  // TODO if need to add another voucher provider, account for it
  TRvouchersValues: [],
  vouchers: {},
};
const initialState = {
  ...DEFAULT_STATE,
};

const localInitState = librawayStorage.get('cart');
if (localInitState) {
  Object.keys(localInitState).forEach(
    (key) => (initialState[key] = localInitState[key]),
  );
}

const cartReducer = (state, { type, payload }) => {
  const currentQuantity = state.items.find(
    ({ id }) => payload?.id === id,
  )?.quantity;

  switch (type) {
    case 'add':
      localPushToDataLayer({ action: 'add', item: payload });

      return {
        ...state,
        items: [...state.items, payload],
      };

    case 'updateQuantity': {
      /* Handle to manage add or remove GTM event */
      if (payload.quantity - currentQuantity > 0) {
        localPushToDataLayer({
          action: 'add',
          item: {
            ...payload,
            quantity: payload.quantity - currentQuantity,
          },
        });
      } else if (payload.quantity - currentQuantity < 0) {
        localPushToDataLayer({
          action: 'remove',
          item: {
            ...payload,
            quantity: currentQuantity - payload.quantity,
          },
        });
      }

      return {
        ...state,
        items: state.items.map((item) => (payload.id === item.id
          ? { ...item, quantity: payload.quantity }
          : item)),
      };
    }

    case 'remove':
      /* Update GTM only if necessary */
      if (currentQuantity !== undefined && payload.quantity !== undefined) {
        localPushToDataLayer({
          action: 'remove',
          item: {
            ...payload,
            quantity:
              currentQuantity !== payload.quantity
                ? currentQuantity - payload.quantity
                : currentQuantity,
          },
        });
      }

      return {
        ...state,
        items: [...state.items.filter(({ id }) => !(id === payload.id))],
      };

    case 'clean':
      return DEFAULT_STATE;

    case 'updateCoupon':
      return {
        ...state,
        coupon: payload,
      };

    case 'cleanCoupon':
      return {
        ...state,
        coupon: null,
      };

    case 'updateOrderId':
      return {
        ...state,
        orderId: payload,
      };

    case 'cleanOrderId':
      return {
        ...state,
        orderId: null,
      };

    case 'updateVouchers':
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          [payload.supplier]: {
            refreshToken: payload.refreshToken,
            items: payload.items,
          },
        },
      };

    default:
      throw new Error('UNHANDLED CASE TO CART REDUCER');
  }
};

const useCart = () => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const [getBoxes, { boxes, loading }] = useGetBoxes();
  const { isWithinWebview } = useAppContext();

  useEffect(getBoxes, []);

  /* Update cart in localStorage */
  useEffect(() => {
    librawayStorage.set('cart', {
      type: state.type,
      items: state.items,
      orderId: state.orderId,
      coupon: state.coupon,
      vouchers: state.vouchers,
    });
  }, [state]);

  /* Coupon */
  const getCoupon = (prop) => {
    if (!prop) return state.coupon;
    return !isEmpty(state.coupon) ? state.coupon[prop] : null;
  };

  const updateCoupon = (couponData) => {
    dispatch({ type: 'updateCoupon', payload: couponData });
  };

  const cleanCoupon = () => {
    dispatch({ type: 'cleanCoupon' });
  };

  /* Items */
  const cartLimit = boxes
    ? Math.max(...boxes.map(({ quantity }) => quantity))
    : 0;

  const itemsCount = sumBy(state.items, ({ quantity = 0 }) => quantity);

  useEffect(() => {
    if (isWithinWebview) {
      callFlutterHandler('cartUpdate', [{ cartItemsCount: itemsCount }]);
    }
  }, [isWithinWebview, itemsCount]);

  const voidSpacesLeft = Math.max(0, cartLimit - itemsCount);

  const itemsPrice = state.items.reduce(
    (a, { unitPrice, quantity }) => a + unitPrice * quantity,
    0,
  );

  const boxCompleted = boxes && findLast(boxes, ({ quantity }) => itemsCount === quantity);

  const couponDiscount = () => {
    const { type, amount } = state.coupon || {};
    if (type === 'FIXED') return amount;
    if (type === 'PERCENTAGE') return (itemsPrice / 100) * amount;
    return 0;
  };

  const vouchersDiscount = Object.keys(state.vouchers)
    .flatMap((supplier) => state.vouchers[supplier]?.items ?? [])
    .reduce((acc, voucher) => acc + (voucher.count * voucher.value) / 100, 0);

  const shippingPrice = !boxCompleted || (state.coupon && state.coupon.freeShipping === true)
    ? 0
    : boxCompleted.shippingPrice;

  const payableVouchersAmount = itemsPrice - couponDiscount();

  const cartPrice = itemsPrice + shippingPrice - couponDiscount() - vouchersDiscount;

  const nextBox = boxes
    ? boxes
      .sort((a, b) => a.quantity - b.quantity)
      .find(({ quantity }) => quantity > itemsCount)
    : 0;

  const addSingleItemCart = ({
    id,
    dishId,
    title,
    price,
    dimension,
    grams,
    image,
    quantity,
  }) => {
    const { items } = state;
    const foundItem = items.find((item) => id === item.id);

    if (voidSpacesLeft > 0) {
      if (foundItem) {
        dispatch({
          type: 'updateQuantity',
          payload: {
            id,
            quantity: foundItem.quantity + quantity,
            dishId,
            dimension,
            grams,
            title,
            unitPrice: price,
            image,
          },
        });
      } else {
        dispatch({
          type: 'add',
          payload: {
            id,
            dishId,
            dimension,
            grams,
            title,
            unitPrice: price,
            quantity,
            image,
          },
        });
      }
    }
  };

  const addCart = (itemsToAdd) => {
    if (Array.isArray(itemsToAdd)) {
      itemsToAdd.forEach(addSingleItemCart);
    } else {
      addSingleItemCart(itemsToAdd);
    }
  };

  const removeItem = (payload) => {
    dispatch({ type: 'remove', payload });
  };

  const updateQuantityCart = (itemToUpdate) => {
    const { id, quantity } = itemToUpdate;
    const { items } = state;
    const foundItem = items.find((item) => id === item.id);

    if (quantity && !foundItem) {
      addSingleItemCart(itemToUpdate);
    } else if (quantity) {
      dispatch({ type: 'updateQuantity', payload: itemToUpdate });
    } else {
      removeItem(itemToUpdate);
    }
  };

  const updateVouchers = (voucherSupplierValues) => {
    dispatch({ type: 'updateVouchers', payload: voucherSupplierValues });
  };

  const cleanCart = () => {
    dispatch({ type: 'clean' });
  };

  const updateOrderId = (orderId) => {
    dispatch({ type: 'updateOrderId', payload: orderId });
  };

  const cleanOrderId = () => {
    dispatch({ type: 'cleanOrderId' });
  };

  return [
    {
      boxesLoading: loading,
      cartItems: state.items,
      cartPrice,
      couponAmount: couponDiscount(),
      boxCompleted,
      boxes,
      getCoupon,
      itemsCount,
      itemsPrice,
      nextBox,
      orderId: state.orderId,
      voidSpacesLeft,
      payableVouchersAmount,
      vouchers: state.vouchers,
    },
    {
      addCart,
      cleanCart,
      cleanCoupon,
      cleanOrderId,
      removeItem,
      updateCoupon,
      updateOrderId,
      updateQuantityCart,
      updateVouchers,
    },
  ];
};

export default useCart;
