import gql from 'graphql-tag';

const getDietsQuery = gql`
  query Diets {
    diets {
      id,
      label,
      icon {
        url
      }
    }
  }
`;

const getBoxesQuery = gql`
  query Boxes {
    boxes {
      id,
      name,
      quantity,
      shippingPrice: shipping_price,
    }
  }
`;

const getIngredientsQuery = gql`
  query IngredientTrees($search: String) {
    ingredientTrees(search: $search) {
      id,
      name,
      icon { url },
      type,
      food {
        name,
        image { url }
      }
    }
  }
`;

const getFreePlansQuery = gql`
  query FreePlans {
    freePlans {
      id
      label
      description
      available
    }
  }
`;

export {
  getBoxesQuery,
  getDietsQuery,
  getFreePlansQuery,
  getIngredientsQuery,
};
