import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from './Button';
import useStyles from './style';

const MenuItem = ({
  link,
  name,
  icon: Icon,
  disabled,
  onClick,
}) => {
  const { t } = useTranslation(['header']);
  const history = useHistory();
  const match = useRouteMatch(`/${link || name}`);
  const { menuItemsContainer } = useStyles();

  const handleLinkClick = (route) => () => {
    if (onClick) {
      onClick(route);
    } else {
      history.push(`/${route}`);
    }
  };

  return (
    <Button
      disabled={disabled}
      onClick={handleLinkClick(link || name)}
      active={match && match.isExact}
    >
      <Grid container alignItems="center" className={menuItemsContainer}>
        {Icon && (
          <Grid item>
            <Grid container alignItems="center">
              <Icon width={20} height={30} />
            </Grid>
          </Grid>
        )}

        <Grid item>{t(`${name}`)}</Grid>
      </Grid>
    </Button>
  );
};

MenuItem.propTypes = {
  link: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

MenuItem.defaultProps = {
  link: null,
  disabled: false,
  icon: null,
  onClick: null,
};

export default MenuItem;
