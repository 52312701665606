import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useAuthContext } from '../../contexts/AuthContext';
import useResponsive from '../../hooks/useResponsive';
import Drawer from '../../components/Drawer';
import Link from '../../components/Link';
import Button from '../../components/Button';
import Divider from '../../components/Divider';
import librawayLogo from '../../assets/librawayLogoExtended.svg';
import { ReactComponent as TimesIcon } from '../../assets/icons/times.svg';
import comingSoonIcon from '../../assets/icons/nastro_presto-online.svg';
import useStyles from './style';
import { drawerItems } from './items';
import { itemsFilter } from '../../libraries/helpers';

/**
 * Component
 */
const MainDrawer = ({ open, onClose, shrink }) => {
  const { t } = useTranslation('header');
  const history = useHistory();
  const { isLoggedIn, user: { hasPlan } } = useAuthContext();
  const { isSmallView } = useResponsive();
  const {
    container,
    timesButtonStyle,
    drawerHeaderStyle,
    headerHeight,
    planButtonStyle,
    signinStyle,
  } = useStyles({ shrink });

  return (
    <Drawer
      open={open}
      onClose={onClose}
    >
      <Grid container style={{ paddingBottom: 50 }}>
        {/* Header */}
        <Container className={container} style={{ height: 'auto' }}>
          <Grid item xs={12}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classNames(drawerHeaderStyle, headerHeight)}
            >
              <Grid item xs={4} />
              <Grid
                item
                xs={4}
                container
                alignItems="center"
                justify="center"
              >
                <img
                  src={librawayLogo}
                  alt="Libraway logo"
                  height={shrink ? 40 : 60}
                />
              </Grid>
              <Grid
                item
                xs={4}
                container
                alignItems="center"
                justify="flex-end"
              >
                <ButtonBase
                  disableRipple
                  className={timesButtonStyle}
                  onClick={onClose}
                >
                  <TimesIcon />
                </ButtonBase>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        {/* Body */}
        <Grid item xs={12}>
          <Grid
            container
            justify="center"
            spacing={isSmallView ? 2 : 1}
            style={{ paddingTop: 30, width: '100%', margin: 0 }}
          >
            {isSmallView && (!isLoggedIn || !hasPlan) && (
              <Grid
                item
                xs={12}
                style={{ paddingBottom: 30 }}
                container
                justify="center"
              >
                <Button
                  filled
                  fitted
                  className={planButtonStyle}
                  onClick={() => { history.push('/it/come-funziona'); }}
                >
                  {t('active-plan')}
                </Button>
              </Grid>
            )}

            {drawerItems
              .filter(itemsFilter(isLoggedIn, 'justAuthUserIs'))
              .filter(itemsFilter(hasPlan, 'justUserPlanIs'))
              .filter(itemsFilter(isSmallView, 'justDeviceMobileIs'))
              .map(({
                comingSoon,
                disabled,
                divider,
                link,
                name,
                onClick,
                specialAction,
              }) => {
                const getItemStyle = () => {
                  if (specialAction === 1) return signinStyle;
                  return null;
                };

                return (
                  !divider ? (
                    <Grid
                      item
                      xs={12}
                      container
                      justify="center"
                    >
                      <Typography variant="body2">
                        <Link
                          disabled={disabled}
                          to={link || name}
                          onClick={onClick}
                          color="black"
                        >
                          <span className={getItemStyle()}>
                            {t(name)}
                          </span>
                        </Link>
                        {comingSoon && (
                          <img
                            src={comingSoonIcon}
                            alt="Coming soon"
                            height={25}
                            style={{
                              position: 'absolute',
                              marginLeft: 16,
                              marginTop: -4,
                            }}
                          />
                        )}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item style={{ width: 40 }}>
                      <Divider
                        margin={isSmallView ? 2 : 4}
                        color="secondary"
                        thickness="fat"
                      />
                    </Grid>
                  )
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

MainDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shrink: PropTypes.bool,
};

MainDrawer.defaultProps = {
  shrink: false,
};

export default MainDrawer;
