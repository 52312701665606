import { makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles(({ spacing }) => ({
  uppercase: { textTransform: 'uppercase' },
  lineThrough: { textDecoration: 'line-through' },
  bold: { fontWeight: 'bold' },
  capitalize: {
    textTransform: 'lowercase',
    '&::first-letter': { textTransform: 'uppercase' },
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: spacing(),
    borderRadius: 10,
  },
}));

export default useClasses;
