import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette: { secondary, white }, breakpoints }) => ({
  banner: {
    position: 'fixed',
    top: -48,
    right: -48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    backgroundColor: secondary.main,
    color: white.main,
    width: 95,
    height: 95,
    zIndex: 1300,
    transform: 'rotate(45deg)',
    fontSize: '0.9rem',
    fontWeight: 900,
    [breakpoints.down('sm')]: {
      top: -65,
      right: -65,
      fontSize: '0.75rem',
    },
  },
}));

export default useStyles;
