import React, { createContext, useContext } from 'react';
import { childrenPropType } from '../components/propTypes';
import useNotifications from '../hooks/useNotifications';

const NotificationContext = createContext([]);

const NotificationProvider = ({ children }) => {
  const notificationState = useNotifications();

  return (
    <NotificationContext.Provider value={notificationState}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotificationContext = () => useContext(NotificationContext);

NotificationProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  NotificationContext,
  NotificationProvider,
  useNotificationContext,
};
