import { useMutation } from '@apollo/react-hooks';
import { readNotificationMutation } from '../../mutations/notifications';

export default () => {
  const [
    readNotification,
    { loading, data, error },
  ] = useMutation(
    readNotificationMutation,
    { refetchQueries: ['Notifications'] },
  );

  return [readNotification, { loading, data, errors: error && error.graphQLErrors }];
};
