import { useMutation } from '@apollo/react-hooks';
import { requestSubscribeMutation } from '../../mutations/generic';

export default () => {
  const [
    requestSubscribe,
    { loading, data, error },
  ] = useMutation(requestSubscribeMutation);

  return [requestSubscribe, { loading, data, errors: error && error.graphQLErrors }];
};
