import gql from 'graphql-tag';

const notificationSubcription = gql`
  subscription OnNotificationCreated {
    notificationCreated {
      id
      # title
      # message
      # action
      # actionPayload: action_payload
      # type {
      #   label,
      #   file { url }
      # }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { notificationSubcription };
