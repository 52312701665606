import React, { useContext } from 'react';
import WidgetContext from './Context';
import Chat from './Chat';
import Footer from './Footer';
import Header from './Header';

export default function Pop(props) {
  const { isOpen } = useContext(WidgetContext);

  return (
    React.createElement(
      'div',
      {
        key: props,
        className: `whatsapp_widget_pop ${isOpen ? 'whatsapp_widget_pop_open' : 'close'}`,
      },
      React.createElement(Header, props),
      React.createElement(Chat, props),
      React.createElement(Footer, props),
    )
  );
}
