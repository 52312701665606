import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
  breakpoints,
  spacing,
  palette: {
    secondary,
    grey,
    common,
  },
}) => ({
  quantityButtonGroup: {
    height: 32,
    backgroundColor: grey.main,
    boxShadow: 'none',
    borderRadius: 999,
    '& button': {
      backgroundColor: common.white,
      border: `1px solid ${grey.dark}`,
      padding: spacing(1),
      lineHeight: 1,
      fontSize: '1rem',
      fontWeight: 900,
      '&:hover': {
        backgroundColor: common.white,
        color: secondary.main,
      },
      '&:disabled': {
        backgroundColor: common.white,
        opacity: 0.5,
      },
      [breakpoints.down('sm')]: {
        padding: spacing(0),
        minWidth: 25,
      },
    },
    '& button:not(:first-child):not(:last-child)': {
      color: ({ isEditing }) => (isEditing ? secondary.main : common.black),
    },
    '& button:first-child, button:last-child': {
      minWidth: 30,
      [breakpoints.down('sm')]: {
        padding: spacing(0),
        minWidth: 25,
      },
    },
    '& button:first-child': {
      borderRight: '0 !important',
      borderRadius: '999px 0 0 999px',
    },
    '& button:last-child': {
      borderLeft: '0 !important',
      borderRadius: '0 999px 999px 0',
    },
  },

  small: {
    minWidth: 0,
  },
}));

export default useStyles;
