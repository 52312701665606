import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';

const CollapseError = ({ errors }) => {
  const { spacing } = useTheme();

  return (
    <Collapse in={errors && errors.length}>
      <Alert severity="error" style={{ marginBottom: spacing(4) }}>
        {errors && errors.length && errors[0].message}
      </Alert>
    </Collapse>
  );
};

CollapseError.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape()),
};

CollapseError.defaultProps = {
  errors: null,
};

export default CollapseError;
