import React from 'react';
import PropTypes from 'prop-types';

const style = {
  color: 'rgb(204, 204, 204)',
  fontSize: '.8em',
};

const Version = ({ numberVersion }) => <span style={style}>{numberVersion}</span>;

Version.propTypes = { numberVersion: PropTypes.string };
Version.defaultProps = { numberVersion: process.env.REACT_APP_LIBRAWAY_VERSION };

export default Version;
