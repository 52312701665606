import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faPinterestP,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { childrenPropType } from '../propTypes';
import useStyles from './style';

const SocialAvatar = ({
  children,
  description,
  disabled,
  image,
  size,
  social,
  url,
}) => {
  const iconSizes = {
    small: { icon: 'sm', style: 32 },
    medium: { icon: '1x', style: 40 },
    large: { icon: 'lg', style: 48 },
  };

  const { avatar, button } = useStyles({
    size: iconSizes[size].style,
    disabled,
  });

  const postUrl = window.location.href;

  const socials = {
    facebook: {
      icon: faFacebookF,
      url: `https://www.facebook.com/sharer/sharer.php?u=${postUrl}`,
    },
    pinterest: {
      icon: faPinterestP,
      url: `https://pinterest.com/pin/create/bookmarklet/?media=${image}&url=${postUrl}&is_video=false&description=${description}`,
    },
    instagram: {
      icon: faInstagram,
      url: '',
    },
    twitter: {
      icon: faTwitter,
      url: `https://twitter.com/share?url=${postUrl}&text=${description}`,
    },
  };

  return (
    <ButtonBase
      href={url || socials[social].url}
      target="_blank"
      className={button}
      onClick={() => {}}
      disabled={disabled}
    >
      <Avatar className={avatar}>
        {social ? (
          <FontAwesomeIcon
            icon={socials[social].icon}
            size={iconSizes[size].icon}
          />
        ) : children}
      </Avatar>
    </ButtonBase>
  );
};

SocialAvatar.propTypes = {
  children: childrenPropType,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  image: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  social: PropTypes.oneOf(['facebook', 'instagram', 'twitter']),
  url: PropTypes.string,
};

SocialAvatar.defaultProps = {
  children: '',
  description: '',
  disabled: false,
  image: '',
  size: 'medium',
  social: '',
  url: '',
};

export default SocialAvatar;
