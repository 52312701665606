import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
  palette: {
    common,
    grey,
    secondary,
    black,
  },
}) => ({
  step: {
    color: black.light,
    width: 20,
    height: 20,
    fontSize: 12,
    backgroundColor: ({ whiteBackground }) => (whiteBackground ? common.white : grey.main),
    borderRadius: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.accomplished': {
      backgroundColor: grey.dark,
      color: common.white,
    },
    '&.accomplished.reach': {
      backgroundColor: secondary.main,
    },
  },
}));

export default useStyles;
