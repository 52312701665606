import { makeStyles } from '@material-ui/core';

const getDimension = (size) => {
  if (size === 'small') return 0.5;
  if (size === 'regular') return 1.5;
  if (size === 'big') return 2;
  return 0;
};

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  dotStyle: {
    backgroundColor: primary.dark,
    width: ({ size }) => spacing(getDimension(size)),
    height: ({ size }) => spacing(getDimension(size)),
    borderRadius: 999,
  },
}));

export default useStyles;
