const pushToDataLayer = (data) => {
  window.dataLayer.push({
    dimension1: process.env.REACT_APP_LIBRAWAY_VERSION,
    ...data,
  });
};

/**
 * Data layer GTM custom event
 *
 * @param {String} eventCategory
 * @param {String} eventAction
 * @param {String} eventLabel
 */
const pushCustomEventToDataLayer = (eventCategory, eventAction, eventLabel) => {
  pushToDataLayer({
    event: 'custom_event',
    eventCategory,
    eventAction,
    eventLabel,
  });
};

/**
 * Data layer GTM ecommerce impressions
 *
 * @param {Array} impressions
 */
const pushImpressionsToDataLayer = (impressions) => {
  pushToDataLayer({
    ecommerce: {
      currencyCode: 'EUR',
      impressions,
    },
  });
};

/**
 * Map function to dataLayer
 *
 * @param {String} list
 * @returns Function
 */
const mapCardToDataLayer = (list) => ({
  id,
  price,
  title,
  categories,
  sizes,
}, index) => ({
  name: title,
  id,
  price,
  brand: 'Libraway',
  category: categories?.length && categories[0].name,
  variant: sizes && sizes.length ? sizes[0].dimension : null,
  position: index + 1,
  list,
});

export {
  mapCardToDataLayer,
  pushCustomEventToDataLayer,
  pushImpressionsToDataLayer,
  pushToDataLayer,
};
