import React from 'react';
import useStyles from './style';

const VersionBanner = () => {
  const { banner } = useStyles();
  const { REACT_APP_VERSION_BANNER: versionBanner } = process.env;

  if (versionBanner) {
    return <div className={banner}>{versionBanner}</div>;
  }

  return null;
};

export default VersionBanner;
