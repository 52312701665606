import React from 'react';
import { dispatch } from 'use-bus';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import ButtonBase from '@material-ui/core/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(({ spacing, palette: { primary, white } }) => ({
  container: {
    position: 'relative',
    border: `3px solid ${primary.dark}`,
    borderRadius: 30,
    height: spacing(5),
    backgroundColor: white.main,
    flexGrow: 1,
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },

  button: {
    position: 'absolute',
    right: -1,
    width: spacing(6.5),
    height: '100%',
    border: `3px solid ${primary.dark}`,
    borderRadius: '0 30px 30px 0',
    backgroundColor: primary.dark,
  },
}));

const InlineInputButton = () => {
  const { t } = useTranslation('footer');
  const { container, button } = useStyles();

  const handleClick = () => {
    dispatch({ type: '@@newsletterModal/open' });
  };

  return (
    <>
      <div className={container}>
        <InputBase
          placeholder={t('newsletter')}
          readOnly
          onClick={handleClick}
          style={{ width: '100%' }}
        />
        <ButtonBase
          className={button}
          onClick={handleClick}
        >
          <FontAwesomeIcon
            size="lg"
            icon={faChevronRight}
          />
        </ButtonBase>
      </div>
    </>
  );
};

export default InlineInputButton;
