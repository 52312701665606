import {
  black,
  darkFacebook,
  darkGoogle,
  darkGrey,
  lightGrey,
  lightBlack,
  lightPrimaryColor,
  lightSecondaryColor,
  mediumFacebook,
  mediumGoogle,
  mediumGreen,
  mediumGrey,
  mediumDarkPrimaryColor,
  mediumPrimaryColor,
  mediumRed,
  mediumYellow,
  primaryColor,
  secondaryColor,
  white,
} from '../common/constants';

const palette = {
  primary: {
    light: lightPrimaryColor.hex,
    main: mediumPrimaryColor.hex,
    mainDark: mediumDarkPrimaryColor.hex,
    dark: primaryColor.hex,
    contrastText: white.hex,
  },

  secondary: {
    light: lightSecondaryColor.hex,
    main: secondaryColor.hex,
    contrastText: white.hex,
  },

  white: {
    main: white.hex,
    contrastText: black.hex,
  },

  black: {
    light: lightBlack.hex,
    main: black.hex,
    dark: black.hex,
    contrastText: white.hex,
  },

  grey: {
    light: lightGrey.hex,
    main: mediumGrey.hex,
    dark: darkGrey.hex,
    contrastText: white.hex,
  },

  red: {
    main: mediumRed.hex,
  },

  green: {
    main: mediumGreen.hex,
  },

  yellow: {
    main: mediumYellow.hex,
  },

  facebook: {
    main: mediumFacebook.hex,
    dark: darkFacebook.hex,
  },

  google: {
    main: mediumGoogle.hex,
    dark: darkGoogle.hex,
  },
};

export default palette;
