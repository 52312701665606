import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useStyles from './style';

const addLabel = '+';
const subLabel = '-';

const Quantity = ({
  allowZero,
  quantity,
  onChange,
  isEditing,
  items,
  voidSpacesLeft,
}) => {
  const { quantityButtonGroup, small } = useStyles({ isEditing });
  const [anchorElement, setAnchorElement] = useState(null);

  const handleSub = () => { onChange(quantity - 1); };
  const handleAdd = () => { onChange(quantity + 1); };

  const handleQuantityClick = ({ currentTarget }) => { setAnchorElement(currentTarget); };
  const handleClose = () => { setAnchorElement(null); };
  const handleItemClick = (value, onClick) => () => {
    handleClose();
    onChange(value);
    if (onClick) {
      onClick(value);
    }
  };

  // eslint-disable-next-line react/prop-types
  const renderItem = ({ onClick, label: itemLabel, value }) => (
    <MenuItem
      key={value}
      onClick={handleItemClick(value, onClick)}
      dense
    >
      {itemLabel}
    </MenuItem>
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Grid container alignItems="center">
            <ButtonGroup
              size="small"
              variant="contained"
              className={quantityButtonGroup}
            >
              <Button onClick={handleSub} disabled={quantity === (allowZero ? 0 : 1)}>
                {subLabel}
              </Button>
              <Button onClick={handleQuantityClick}>
                {quantity}
              </Button>
              <Button onClick={handleAdd} disabled={!voidSpacesLeft}>
                {addLabel}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>

      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleClose}
        classes={{ paper: small }}
      >
        {items && items.map(renderItem)}
      </Menu>
    </>
  );
};

Quantity.propTypes = {
  allowZero: PropTypes.bool,
  quantity: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape),
  voidSpacesLeft: PropTypes.bool.isRequired,
};

Quantity.defaultProps = {
  allowZero: false,
  isEditing: false,
  items: null,
};

export default Quantity;
