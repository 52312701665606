import { primaryColor, secondaryColor, white } from '../common/constants';

const getOverrides = ({ breakpoints, spacing }) => ({
  MuiCssBaseline: {
    '@global': {
      '@font-face': 'Nunito Sans, sans-serif',
      html: {
        fontSize: 16,
      },
      body: {
        backgroundColor: white.hex,
        fontSize: '1rem',
        letterSpacing: '0.03rem',
        [breakpoints.down('sm')]: {
          letterSpacing: '0.01rem',
        },
      },
      a: {
        color: secondaryColor.hex,
        fontWeight: 900,
        textDecoration: 'none',
      },
    },
  },

  MuiBadge: {
    badge: {
      padding: '0 6px 0 7px',
    },
    colorPrimary: {
      backgroundColor: primaryColor.hex,
    },
  },

  MuiContainer: {
    root: {
      [breakpoints.up('md')]: {
        padding: spacing(0, 4),
      },
    },
  },

  MuiBackdrop: {
    root: {
      backgroundColor: `rgba(${secondaryColor.rgb.join(',')}, 0.5)`,
    },
  },

  MuiAppBar: {
    colorDefault: {
      backgroundColor: 'transparent',
    },
  },

  MuiToolbar: {
    root: { height: '100%' },
  },

  MuiMenu: {
    paper: {
      minWidth: spacing(25),
    },
  },

  MuiListItem: {
    root: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
    button: {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    divider: {
      borderBottom: 0,
      marginBottom: spacing(2),
    },
  },

  MuiMenuItem: {
    root: { fontSize: '.9em' },
  },

  MuiButtonBase: {
    root: {
      fontFamily: 'Nunito Sans, sans-serif',
    },
  },

  MuiButton: {
    root: {
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '1rem',
      letterSpacing: 'inherit',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: `rgba(${secondaryColor.rgb.join(',')}, 0.1)`,
      },
    },
    outlined: {
      border: 0,
      fontWeight: 900,
      padding: `${spacing(0.75)}px ${spacing()}px`,
    },
  },

  MuiTypography: {
    colorPrimary: {
      color: primaryColor.hex,
    },
  },

  MuiCheckbox: {
    colorPrimary: {
      '&$checked': {
        color: primaryColor.hex,
      },
    },
  },
});

export default getOverrides;
