import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import useStyles from './style';

const Input = forwardRef(({
  autocomplete,
  borderColor,
  controlledValue,
  defaultValue,
  dense,
  disabled,
  endAdornment,
  fat,
  fullWidth,
  hasError,
  inputProps,
  isValidated,
  label,
  max,
  maxlength,
  min,
  multiline,
  name,
  nullable,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  readOnly,
  required,
  square,
  startAdornment,
  step,
  type,
  value,
}, ref) => {
  const [inputValue, setInputValue] = useState(value);
  const voidInput = nullable && inputValue;
  const {
    labelStyle,
    root,
    input,
    checkIcon,
    errorIcon,
  } = useStyles({
    borderColor,
    dense,
    disabled,
    fat,
    fullWidth,
    hasError,
    isValidated,
    multiline,
    readOnly,
    square,
    voidInput,
  });

  const getRows = () => {
    if (typeof multiline === 'number') return multiline;
    if (typeof multiline === 'boolean' && multiline) return 3;
    return 1;
  };

  const enhancedSetInputValue = (newValue) => {
    setInputValue(newValue);
    onChange(newValue);
  };

  const handleInputChange = ({ target: { value: inputChangeValue } }) => {
    enhancedSetInputValue(inputChangeValue);
  };

  const getEndAdornment = () => {
    if (endAdornment) return endAdornment;
    if (isValidated) return <CheckIcon className={checkIcon} />;
    if (hasError) {
      return (
        <Tooltip title={hasError} disableHoverListener={hasError === true}>
          <ErrorIcon className={errorIcon} />
        </Tooltip>
      );
    }
    return null;
  };

  const renderLabel = () => (
    <span className={labelStyle}>
      {label}
      {required && <b>&nbsp;*</b>}
    </span>
  );

  return (
    <Grid container direction="column">
      {label && renderLabel()}
      <InputBase
        autoComplete={autocomplete}
        classes={{ root, input }}
        defaultValue={defaultValue || (!controlledValue && controlledValue !== '' ? inputValue : undefined)}
        disabled={disabled}
        endAdornment={getEndAdornment()}
        inputProps={{ ...inputProps, autocomplete }}
        inputRef={ref}
        max={max}
        maxlength={maxlength}
        min={min}
        multiline={multiline}
        name={name}
        onBlur={onBlur}
        onChange={handleInputChange}
        onFocus={onFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        rows={getRows()}
        startAdornment={startAdornment}
        step={step}
        type={type}
        value={controlledValue}
      />
    </Grid>
  );
});

Input.propTypes = {
  autocomplete: PropTypes.string,
  borderColor: PropTypes.string,
  controlledValue: PropTypes.string,
  defaultValue: PropTypes.string,
  dense: PropTypes.bool,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  fat: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  inputProps: PropTypes.shape(),
  isValidated: PropTypes.bool,
  label: PropTypes.string,
  max: PropTypes.number,
  maxlength: PropTypes.number,
  min: PropTypes.number,
  multiline: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  nullable: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  square: PropTypes.bool,
  startAdornment: PropTypes.node,
  step: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.string,
};

Input.defaultProps = {
  autocomplete: 'on',
  borderColor: 'secondary.main',
  controlledValue: undefined,
  defaultValue: undefined,
  dense: false,
  disabled: false,
  endAdornment: null,
  fat: false,
  fullWidth: true,
  hasError: false,
  inputProps: {},
  isValidated: false,
  label: '',
  max: undefined,
  maxlength: undefined,
  min: undefined,
  multiline: false,
  name: '',
  nullable: false,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  placeholder: '',
  readOnly: false,
  required: false,
  square: false,
  startAdornment: null,
  step: undefined,
  type: 'text',
  value: '',
};

export default Input;
