const props = {
  MuiContainer: {
    maxWidth: 'xl',
  },

  MuiLink: {
    color: 'textPrimary',
    underline: 'none',
    component: 'span',
  },

  MuiMenu: {
    getContentAnchorEl: null,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    transformOrigin: { vertical: 'top', horizontal: 'center' },
    elevation: 4,
  },
};

export default props;
