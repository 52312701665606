import { createMuiTheme } from '@material-ui/core/styles';

import mainTheme from '../main';
import getOverrides from './overrides';
import getPalette from './palette';
import typographyEnhancer from './typography';

const theme = createMuiTheme({
  ...mainTheme,
  overrides: getOverrides(mainTheme),
  palette: getPalette(mainTheme),
});

export default typographyEnhancer(theme);
