import { lazy } from 'react';
import { getPathAliases } from './libraries/helpers';

const AboutUs = lazy(() => import('./pages/AboutUs'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Contacts = lazy(() => import('./pages/Contacts'));
const Dish = lazy(() => import('./pages/Dish'));
const Gamification = lazy(() => import('./pages/Gamification'));
const Home = lazy(() => import('./pages/Home'));
const HowItWorks = lazy(() => import('./pages/HowItWorks'));
const Login = lazy(() => import('./pages/Authentication/Login'));
const Logout = lazy(() => import('./pages/Authentication/Logout'));
const NoMatch = lazy(() => import('./pages/NoMatch'));
const Notifications = lazy(() => import('./pages/Notifications'));
const Dishes = lazy(() => import('./pages/OrderDishes/Dishes'));
const Recipes = lazy(() => import('./pages/OrderDishes/Recipes'));
const OrderDetails = lazy(() => import('./pages/OrderDetails'));
const OrderHistory = lazy(() => import('./pages/OrderHistory'));
const PasswordRecovery = lazy(() => import('./pages/Authentication/PasswordRecovery'));
const PasswordReset = lazy(() => import('./pages/Authentication/PasswordReset'));
const Recipe = lazy(() => import('./pages/Recipe'));
const Registration = lazy(() => import('./pages/Authentication/Registration'));
const ShoppingLists = lazy(() => import('./pages/ShoppingLists'));
const StaticPage = lazy(() => import('./pages/StaticPage'));
const Terms = lazy(() => import('./pages/Terms'));
const UserProfile = lazy(() => import('./pages/UserProfile'));

const routes = [{

  /**
   * Public routes - authenticated: false|undefined
   */
  path: '/',
  title: 'home',
  main: Home,
  exact: true,
  whiteBackgroundHeader: true,
}, {
  path: getPathAliases('about-us'),
  title: 'about-us',
  main: AboutUs,
  whiteBackgroundHeader: true,
}, {
  path: getPathAliases('how-it-works'),
  title: 'how-it-works',
  main: HowItWorks,
  whiteBackgroundHeader: true,
}, {
  path: getPathAliases('dishes'),
  title: 'dishes',
  main: Dishes,
  exact: true,
  showBottomCart: true,
  whiteBackgroundHeader: true,
}, {
  path: getPathAliases('recipes'),
  title: 'recipes',
  main: Recipes,
  exact: true,
}, {
  path: ['/dishes/:dishId', '/it/piatti/:dishSlug/:dishId'],
  // path: getPathAliases('dishes', ':dishSlug', ':dishId'),
  main: Dish,
  showBottomCart: true,
}, {
  path: ['/recipes/:recipeId', '/it/ricette/:recipeSlug/:recipeId'],
  // path: getPathAliases('recipes', ':recipeSlug', ':recipeId'),
  main: Recipe,
}, {
  path: getPathAliases('checkout', ':step?/:response?'),
  main: Checkout,
}, {
  path: getPathAliases('order-details', ':response?'),
  main: OrderDetails,
}, {
  path: getPathAliases('notifications'),
  main: Notifications,
}, {
  path: getPathAliases('contacts'),
  main: Contacts,
}, {
  path: getPathAliases('terms'),
  main: Terms,
}, {
  path: getPathAliases('contest-invitation'),
  title: 'contest-invitation',
  main: StaticPage,
  props: { contentSlug: 'contest-invitation' },
},

/**
 * Private routes - authenticated: true
 */
{
  path: getPathAliases('profile'),
  title: 'user-profile',
  main: UserProfile,
  authenticated: true,
}, {
  path: getPathAliases('orders'),
  title: 'order-history',
  main: OrderHistory,
  authenticated: true,
}, {
  path: getPathAliases('shopping-lists'),
  title: 'shopping-lists',
  main: ShoppingLists,
  authenticated: true,
}, {
  path: getPathAliases('gamification'),
  title: 'gamification',
  main: Gamification,
  authenticated: true,
  whiteBackgroundHeader: true,
},

/**
 * Routes without header and footer
 */
{
  path: getPathAliases('signin'),
  title: 'signin',
  main: Login,
  outOfLayoutRoutes: true,
}, {
  path: getPathAliases('signup'),
  title: 'signup',
  main: Registration,
  outOfLayoutRoutes: true,
}, {
  path: getPathAliases('signout'),
  title: 'signout',
  main: Logout,
  outOfLayoutRoutes: true,
}, {
  path: getPathAliases('password-recovery'),
  title: 'password-recovery',
  main: PasswordRecovery,
  outOfLayoutRoutes: true,
}, {
  path: getPathAliases('password-reset'),
  title: 'password-reset',
  main: PasswordReset,
  outOfLayoutRoutes: true,
},

/**
 * Handling errors
 */
{
  title: '404',
  main: NoMatch,
}];

export default routes;
