/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { childrenPropType } from '../propTypes';

const HtmlText = ({ text, children }) => (
  <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children || text) }} />
);

HtmlText.propTypes = {
  text: PropTypes.string,
  children: childrenPropType,
};

HtmlText.defaultProps = {
  text: '',
  children: '',
};

export default HtmlText;
