import React from 'react';
import Notification from 'react-web-notification';
import { useNotificationContext } from '../../contexts/NotificationContext';

const WebNotification = () => {
  const {
    ignore,
    title,
    options,
    setIgnore,
    onClick,
  } = useNotificationContext();

  const setIgnoreTrue = () => { setIgnore(true); };
  const setIgnoreFalse = () => { setIgnore(false); };

  return (
    <Notification
      ignore={ignore || !(title || options.body)}
      onPermissionGranted={setIgnoreFalse}
      onPermissionDenied={setIgnoreTrue}
      onClick={onClick}
      notSupported={setIgnoreTrue}
      title={title || 'Libraway'}
      options={options}
    />
  );
};

export default WebNotification;
