import { useState } from 'react';

const useModalOpen = (defaultState = false) => {
  const [opened, setOpened] = useState(defaultState);

  const openModal = () => { setOpened(true); };
  const closeModal = () => { setOpened(false); };
  const toggleModal = () => { setOpened((prevState) => !prevState); };

  return {
    opened,
    openModal,
    closeModal,
    toggleModal,
  };
};

export default useModalOpen;
