import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as dateFnsLocales from 'date-fns/locale';
import { styled } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Switch, Route, Redirect } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {
  getLocaleDateFnsFormat,
  librawaySessionStorage,
} from './libraries/helpers';
import routes from './routes';
import { GamificationProvider } from './contexts/GamificationContext';
import { useAuthContext } from './contexts/AuthContext';
import { CartProvider } from './contexts/CartContext';
import { useAppContext } from './contexts/AppContext';
import useModalOpen from './hooks/useModalOpen';
import { NotificationProvider } from './contexts/NotificationContext';
import TopBanner from './layout/TopBanner';
import RasaWidget from './components/RasaWidget';
import WebNotification from './components/WebNotification';
import WhatsappWidget from './components/WhatsappWidget/WhatsappWidget';
import VersionBanner from './components/VersionBanner';
import WelcomeModal from './components/WelcomeModal';
import Page from './components/Page';
import Header from './layout/Header';

const FullContainer = styled('div')({ width: '100%' });

const App = () => {
  const {
    i18n: { language: currentLanguage },
  } = useTranslation();
  const locale = dateFnsLocales[getLocaleDateFnsFormat(currentLanguage)];
  const { isLoggedIn } = useAuthContext();
  const { isWithinWebview } = useAppContext();
  const {
    opened: bannerShown,
    closeModal: hideBanner,
    openModal: showBanner,
  } = useModalOpen(false);

  useEffect(() => {
    setTimeout(() => {
      if (
        !isLoggedIn &&
        !librawaySessionStorage.get('newsletterBanner') &&
        !isWithinWebview
      ) {
        showBanner();
      }
    }, 2000);
  }, []);

  const handleClose = () => {
    hideBanner();
    librawaySessionStorage.set('newsletterBanner', true);
  };

  /* eslint-disable react/prop-types */
  const mapRoutes = ({
    path,
    title,
    exact,
    main,
    props,
    authenticated,
    showBottomCart,
    whiteBackgroundHeader,
    contrastedHeader,
    outOfLayoutRoutes,
  }) => (
    <Route
      key={`${path}-${title}`}
      path={path}
      exact={exact}
      render={({ location }) =>
        authenticated && isLoggedIn === undefined ? (
          <></>
        ) : authenticated && isLoggedIn === false ? (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        ) : (
          <>
            {!isWithinWebview && (
              <TopBanner onClose={handleClose} bannerShown={bannerShown} />
            )}
            {!outOfLayoutRoutes && !isWithinWebview && (
              <Header
                contrasted={contrastedHeader}
                whiteBackground={whiteBackgroundHeader}
                bannerShown={bannerShown}
              />
            )}
            <Page
              component={main}
              title={title}
              props={props}
              showBottomCart={showBottomCart}
              bannerShown={bannerShown}
            />
          </>
        )
      }
    />
  );
  /* eslint-enable react/prop-types */

  return (
    <NotificationProvider>
      <CartProvider>
        <GamificationProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            {!isWithinWebview && false && <RasaWidget />}
            {!isWithinWebview && <WhatsappWidget />}
            <WebNotification />
            <FullContainer>
              <WelcomeModal />
              <VersionBanner />
              <Switch>{routes.map(mapRoutes)}</Switch>
            </FullContainer>
          </MuiPickersUtilsProvider>
        </GamificationProvider>
      </CartProvider>
    </NotificationProvider>
  );
};

export default App;
