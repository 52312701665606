import { white, lightPrimaryColor } from '../common/constants';

const getPalette = (mainTheme) => ({
  ...mainTheme.palette,

  background: {
    primary: white.hex,
    secondary: lightPrimaryColor.hex,
  },
});

export default getPalette;
