import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { childrenPropType } from '../propTypes';
import useStyles from './style';

const Container = styled('div')({
  height: '100%',
  width: '100%',
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Label = styled('span')(({ theme: { spacing } }) => ({
  marginLeft: spacing(2),
}));

const sizes = {
  small: 20,
  normal: 40,
};

const Loading = ({ size, children }) => {
  const { root } = useStyles();
  return (
    <Container>
      <CircularProgress className={root} size={sizes[size]} />
      {children && <Label>{children}</Label>}
    </Container>
  );
};

Loading.propTypes = {
  size: PropTypes.oneOf(['small', 'normal']),
  children: childrenPropType,
};

Loading.defaultProps = {
  size: 'normal',
  children: null,
};

export default Loading;
