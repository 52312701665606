import gql from 'graphql-tag';

const getGameStats = gql`
  query GameStats {
    game_stats {
      previousWeekPoints: previous_week_points,
      weekPoints: week_points,
      previousWeekPosition: previous_week_position,
      weekPosition: week_position,
    }
  }
`;

const getGamePoints = gql`
  query GamePoints {
    game_points {
      id,
      points,
      createdAt: created_at,
      trigger {
        description,
        image { url }
      }
    }
  }
`;

const getGameBadges = gql`
  query GameBadges {
    game_badges {
      id,
      active,
      description,
      image { url }
      imageOff: image_off { url }
    }
  }
`;

const getGameLeaderboard = gql`
  query GameLeaderboard {
    game_rankings(first: 1000) {
      data {
        userId: user_id,
        name,
        points
      }
    }
  }
`;

export {
  getGameStats,
  getGamePoints,
  getGameBadges,
  getGameLeaderboard,
};
