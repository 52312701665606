import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './style';

const NotificationDot = ({ hidden, size }) => {
  const { dotStyle } = useStyles({ size });
  return !hidden ? <div className={dotStyle} /> : null;
};

NotificationDot.propTypes = {
  hidden: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'regular', 'big']),
};

NotificationDot.defaultProps = {
  hidden: false,
  size: 'regular',
};

export default NotificationDot;
