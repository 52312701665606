const typographyEnhancer = (muiTheme) => {
  const theme = muiTheme;
  const fontFamily = 'Nunito Sans, sans-serif';

  theme.typography.fontFamily = fontFamily;

  theme.typography.h1 = {
    fontSize: '3rem',
    fontWeight: 900,
    [muiTheme.breakpoints.down('md')]: {
      fontSize: '1.35rem',
    },
  };

  theme.typography.h2 = {
    fontSize: '1.8rem',
    fontWeight: 900,
    [muiTheme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
  };

  theme.typography.h3 = {
    fontSize: '1.2rem',
    fontWeight: 900,
  };

  theme.typography.h4 = {
    fontSize: '1rem',
    fontWeight: 900,
  };

  theme.typography.h6 = {
    fontSize: '1rem',
    fontWeight: 900,
    color: muiTheme.palette.primary.dark,
  };

  theme.typography.body1 = {
    fontFamily,
    lineHeight: '2.8',
    fontWeight: 300,
    [theme.breakpoints.down('md')]: {
      lineHeight: '2',
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.2',
    },
  };

  theme.typography.body2 = {
    fontFamily,
    lineHeight: '1.6',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      lineHeight: '1',
    },
  };

  return theme;
};

export default typographyEnhancer;
