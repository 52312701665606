import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import Grow from '@material-ui/core/Grow';
import { formatMoney } from '../../../libraries/helpers';
import { useCartContext } from '../../../contexts/CartContext';
import BoxesProgress from '../../BoxesProgress';
import Button from '../../Button';
import QuickCart from './QuickCart';
import MobileBottomCart from './MobileBottomCart';
import { ReactComponent as BoxIcon } from '../../../assets/icons/boxBlank.svg';
import useStyles from '../style';

const BottomCart = () => {
  const history = useHistory();
  const { t } = useTranslation('cart');
  const { breakpoints, useClasses } = useTheme();
  const { uppercase, link } = useClasses();
  const isSmallView = useMediaQuery(breakpoints.down('sm'));
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [{
    boxCompleted,
    boxes,
    boxesLoading,
    itemsCount,
    itemsPrice,
    nextBox,
  }] = useCartContext();
  const {
    cartIcon,
    mainContainer,
    cartContainer,
    collapseContainer,
    collapseInnerContainer,
  } = useStyles({ boxCompleted });

  const handleClick = () => { history.push('/checkout/0'); };

  if (boxesLoading) return null;

  const toggleOpenDetails = () => { setDetailsOpen((prevState) => !prevState); };

  return (
    <Slide direction="up" in={!!itemsCount} mountOnEnter unmountOnExit>
      <Grid container className={mainContainer}>
        {!isSmallView ? (
          <>
            <Grid item container className={collapseContainer}>
              <Collapse in={detailsOpen} style={{ width: '100%' }}>
                <Container>
                  <Grid
                    container
                    justify="center"
                    spacing={2}
                    className={collapseInnerContainer}
                  >
                    <Grid item>
                      <Typography
                        onClick={toggleOpenDetails}
                        variant="body2"
                        style={{ cursor: 'pointer' }}
                      >
                        <b><u>{t('reduce')}</u></b>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <QuickCart />
                  </Grid>
                </Container>
              </Collapse>
            </Grid>

            <Grid
              item
              container
              className={cartContainer}
              alignItems="center"
            >
              <Container style={{ height: '100%' }}>
                <Grid container alignItems="center" style={{ height: '100%' }}>
                  <Grid
                    item
                    container
                    spacing={2}
                    justify="space-between"
                    alignItems="center"
                    style={{ height: '100%' }}
                  >
                    <Grid item xs={4}>
                      <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <Grid container alignItems="center">
                            <Hidden mdDown>
                              <BoxIcon height={55} className={cartIcon} />
                            </Hidden>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography
                                variant="h3"
                                style={{ lineHeight: 1 }}
                              >
                                {boxCompleted ? t('box-completed-title') : t('dishes-in-cart', { count: itemsCount })}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{ lineHeight: 1.2 }}
                              >
                                {boxCompleted
                                  ? t('box-completed-subtitle')
                                  : t('left-dishes-in-cart', { count: nextBox.quantity - itemsCount })}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      style={{ alignSelf: 'flex-start', height: '100%' }}
                    >
                      <Grid
                        container
                        justify="center"
                        alignItems=""
                        spacing={1}
                        style={{ height: '100%' }}
                      >
                        <Grid item xs={12}>
                          <Grow in={!detailsOpen}>
                            <Typography
                              variant="body2"
                              onClick={toggleOpenDetails}
                              className={link}
                              style={{ padding: 0, fontSize: '0.8rem' }}
                              align="center"
                            >
                              <b>{t('show-other')}</b>
                            </Typography>
                          </Grow>
                        </Grid>
                        <Grid item xs={12}>
                          {boxes && (
                            <BoxesProgress
                              itemsCount={itemsCount}
                              boxes={boxes}
                              boxCompleted={!!boxCompleted}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={4}>
                      <Grid container justify="flex-end" alignItems="center" spacing={2}>
                        <Grid item>
                          <Grid container justify="flex-end" alignItems="flex-end">
                            <Typography variant="h3" className={uppercase}>
                              {`${t('total')}: ${formatMoney(itemsPrice)}`}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Button
                            stretched
                            fitted
                            fixOpacity
                            filled={!!boxCompleted}
                            disabled={!boxCompleted}
                            onClick={handleClick}
                          >
                            {t('go-to-order')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </>
        ) : (
          <MobileBottomCart
            itemsCount={itemsCount}
            boxCompleted={!!boxCompleted}
          />
        )}
      </Grid>
    </Slide>
  );
};

export default BottomCart;
