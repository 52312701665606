import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WidgetContext from './Context';
import Icon from './Icon';
import Pop from './Pop';

export default function Widget(props) {
  const {
    position,
    autoOpen,
    autoOpenTimer,
    widgetWidth,
    widgetWidthMobile,
  } = props;

  const { isOpen, handleOpen } = useContext(WidgetContext);
  const [visibility, setVisibility] = useState('hidden');
  const enhancedProps = { ...props, visibility };
  const dynamicStyles = position === 'left' ? {
    alignItems: 'flex-start',
    left: 15,
  } : {
    alignItems: 'flex-end',
    right: 15,
  };

  const setVisible = () => { setVisibility('visible'); };
  const setHidden = () => { setVisibility('hidden'); };

  useEffect(() => {
    const visibilityTimeout = setTimeout(isOpen ? setVisible : setHidden, isOpen ? 0 : 200);
    return () => { clearTimeout(visibilityTimeout); };
  }, [isOpen]);

  useEffect(() => {
    const root = document.querySelector(':root');
    root.style.setProperty('--widget-width', widgetWidth);
    root.style.setProperty('--widget-width-mobile', widgetWidthMobile);
  }, [widgetWidth, widgetWidthMobile]);

  useEffect(() => {
    if (autoOpen) {
      setTimeout(handleOpen, autoOpenTimer);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoOpen, autoOpenTimer]);

  // return (
  //   React.createElement('div', {
  //     key: props,
  //     className: 'whatsapp_widget_wrapper',
  //     style: {
  //       visibility,
  //       bottom: 15,
  //       ...dynamicStyles,
  //     },
  //   }, React.createElement(Pop, enhancedProps)),
  //   React.createElement(Icon, enhancedProps)
  // );

  return ([
    <div
      key="whatsapp_widget_wrapper"
      className="whatsapp_widget_wrapper"
      style={{
        visibility,
        ...dynamicStyles,
      }}
    >
      <Pop {...enhancedProps} />
    </div>,
    <Icon key="whatsapp_widget_icon" {...enhancedProps} />,
  ]);
}

Widget.propTypes = {
  position: PropTypes.string.isRequired,
  autoOpen: PropTypes.bool,
  autoOpenTimer: PropTypes.number,
  widgetWidth: PropTypes.string,
  widgetWidthMobile: PropTypes.string,
};

Widget.defaultProps = {
  autoOpen: false,
  autoOpenTimer: 3000,
  widgetWidth: '300px',
  widgetWidthMobile: '260px',
};
