import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { WhatsAppWidgetContextProvider } from './Context';
import Widget from './Widget';
import HtmlText from '../HtmlText';
import LibrawayLogo from '../../assets/librawayLogo.svg';
import './style.scss';

const chatName = 'Libraway Customer Service';

const WhatsappWidget = () => {
  const { palette: { primary, common: { white, black } } } = useTheme();
  const { t } = useTranslation('common');

  return (
    <WhatsAppWidgetContextProvider>
      <Widget
        phoneNo={process.env.REACT_APP_WHATSAPP_NUMBER}
        position="right"
        widgetWidth="350px"
        widgetWidthMobile="260px"
        autoOpen={false}
        autoOpenTimer={0}
        messageBox
        messageBoxTxt=""
        iconSize={50}
        iconColor={white}
        iconBgColor={primary.dark}
        headerIcon={LibrawayLogo}
        headerIconColor="transparent"
        headerTxtColor={white}
        headerBgColor={primary.dark}
        headerTitle={chatName}
        headerCaption="Online"
        bodyBgColor={primary.light}
        chatPersonName={chatName}
        chatMessage={<HtmlText text={t('whatsapp-widget-default-message')} />}
        footerBgColor={white}
        btnBgColor={white}
        btnTxtColor={black}
        btnTxt={t('whatsapp-widget-button-text')}
        inputPlaceholder={t('whatsapp-widget-input-placeholder')}
      />
    </WhatsAppWidgetContextProvider>
  );
};

export default WhatsappWidget;
