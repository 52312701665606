import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './style';

const BoxesProgress = ({
  itemsCount,
  boxCompleted,
  boxes,
  whiteBackground,
}) => {
  const { palette: { secondary, grey, common } } = useTheme();
  const { step } = useStyles({ whiteBackground });

  const foundIndexRange = boxes.findIndex(({ quantity }) => itemsCount <= quantity);
  const hasOneMoreStep = !!boxes[foundIndexRange + 1];
  const hasOneLessStep = !!boxes[foundIndexRange - 1];
  const cap = boxes[hasOneMoreStep ? foundIndexRange + 1 : foundIndexRange].quantity;
  const progress = (itemsCount / cap) * 100;
  const steps = [boxes[foundIndexRange]];

  if (hasOneMoreStep) { steps.push(boxes[foundIndexRange + 1]); }
  if (hasOneLessStep && !boxCompleted) { steps.unshift(boxes[foundIndexRange - 1]); }

  const stepPositions = steps.map(({ quantity }) => (quantity / cap) * 100);

  return (
    <ProgressBar
      percent={progress}
      stepPositions={stepPositions}
      filledBackground={boxCompleted ? secondary.main : grey.dark}
      unfilledBackground={whiteBackground ? common.white : grey.main}
    >
      {steps.map(({ name, quantity }) => (
        <Step key={`${name}-${quantity}`}>
          {({ accomplished }) => (
            <div className={classNames(step, { accomplished, reach: boxCompleted })}>
              {quantity}
            </div>
          )}
        </Step>
      ))}
    </ProgressBar>
  );
};

BoxesProgress.propTypes = {
  itemsCount: PropTypes.number,
  boxCompleted: PropTypes.bool,
  boxes: PropTypes.arrayOf(PropTypes.shape()),
  whiteBackground: PropTypes.bool,
};

BoxesProgress.defaultProps = {
  itemsCount: 0,
  boxCompleted: false,
  boxes: [],
  whiteBackground: false,
};

export default BoxesProgress;
