import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import useStyles from './style';

const Button = ({
  disabled,
  children,
  active,
  onClick,
}) => {
  const { main } = useStyles({ active, disabled });

  return (
    <ButtonBase
      className={main}
      disableRipple
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  );
};

Button.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.element,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  active: false,
  disabled: false,
  children: null,
  onClick: () => {},
};

export default Button;
