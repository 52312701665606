import React, { createContext, useContext } from 'react';
import { childrenPropType } from '../components/propTypes';
import useCart from '../hooks/useCart';

const CartContext = createContext([]);

const CartProvider = ({ children }) => {
  const cartState = useCart();

  return (
    <CartContext.Provider value={cartState}>
      {children}
    </CartContext.Provider>
  );
};

const useCartContext = () => useContext(CartContext);

CartProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  CartContext,
  CartProvider,
  useCartContext,
};
