import PropTypes from 'prop-types';

const childrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
  PropTypes.func,
  PropTypes.shape(),
]);

// eslint-disable-next-line import/prefer-default-export
export { childrenPropType };
