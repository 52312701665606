import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { defaultUpdateQuery } from '../../../libraries/helpers';
import { getNotificationsQuery } from '../../queries/notifications';

export default ({ filters, lazy = false } = {}) => {
  const [loadingFetchMore, setLoadingFetchMore] = useState(false);
  const [
    getNotifications,
    {
      loading,
      data,
      fetchMore,
      refetch,
    }] = useLazyQuery(getNotificationsQuery);

  useEffect(() => {
    if (!lazy) {
      getNotifications();
    }
  }, [getNotifications, filters, lazy]);

  const { me: { notifications: { edges, pageInfo } } } = data || { me: { notifications: {} } };

  return {
    getNotifications,
    loading,
    data: edges || data,
    loadingFetchMore,
    refetch,
    hasNextPage: pageInfo && pageInfo.hasNextPage,
    fetchMore: data
      ? () => {
        setLoadingFetchMore(true);
        fetchMore({
          variables: { after: pageInfo ? pageInfo.endCursor : null },
          updateQuery: defaultUpdateQuery('notifications', setLoadingFetchMore),
        });
      }
      : fetchMore,
  };
};
