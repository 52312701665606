import gql from 'graphql-tag';

const notificationFragment = gql`
  fragment MainNotificationData on Notification {
    id
    title
    message
    action
    actionPayload: action_payload
    type { label }
    createdAt: created_at,
    readAt: read_at,
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { notificationFragment };
