import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ spacing }) => ({
  main: {
    minHeight: 40,
    padding: spacing(0, 2),
    borderRadius: 999,
    fontSize: 15,
    transition: 'box-shadow 150ms ease',
    opacity: ({ disabled }) => (disabled ? 0.5 : 1),
    boxShadow: ({ active }) => (active ? '0px 2px 5px 0px rgba(0,0,0,0.2)' : 'none'),
    '&:hover': {
      boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.2)',
    },
  },
}));

export default useStyles;
