const getOverrides = (mainTheme) => ({
  ...mainTheme.overrides,

  MuiContainer: {
    root: {
      [mainTheme.breakpoints.down('sm')]: {
        padding: mainTheme.spacing(0, 4),
      },
    },
  },

  MuiTypography: {
    gutterBottom: {
      marginBottom: mainTheme.spacing(4),
    },
  },
});

export default getOverrides;
