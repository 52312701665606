import { useState, useEffect } from 'react';
import useLocationQuery from './useLocationQuery';
import { librawaySessionStorage } from '../libraries/helpers';

const useAppState = () => {
  const query = useLocationQuery();
  const headerContrasted = useState(false);
  const orderDishesFilters = useState({ dataType: 'dishes', search: '', groupId: query.get('groupId') });
  const [isWithinWebview, setIsWithinWebview] = useState(librawaySessionStorage.get('webview') || !!query.get('webview'));

  useEffect(() => {
    if (query.get('webview')) {
      librawaySessionStorage.set('webview', !!query.get('webview'));
      setIsWithinWebview(!!query.get('webview'));
    }
  }, [query.get('webview')]);

  return {
    headerContrasted,
    orderDishesFilters,
    isWithinWebview,
  };
};

export default useAppState;
