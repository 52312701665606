import gql from 'graphql-tag';

const registerChatSessionMutation = gql`
  mutation RegisterChatSession($token: String!) {
    registerChatSession(token: $token) {
      session
    }
  }
`;

const unregisterChatSessionMutation = gql`
  mutation UnregisterChatSession($token: String!) {
    unregisterChatSession(token: $token) {
      session
    }
  }
`;

export {
  registerChatSessionMutation,
  unregisterChatSessionMutation,
};
