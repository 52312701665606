import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { common, grey, red }, breakpoints }) => ({
  itemContainer: {
    marginBottom: spacing(2),
  },

  trashButton: {
    width: spacing(6),
    height: spacing(6),
    borderRadius: 999,
    boxShadow: 'none',
    padding: spacing(1),
    color: grey.dark,
    backgroundColor: common.white,
    border: `1px solid ${grey.dark}`,
    transition: 'background-color 100ms ease, border-color 100ms ease',
    '&:hover': {
      color: common.white,
      backgroundColor: red.main,
      border: `1px solid ${red.main}`,
    },
    [breakpoints.down('sm')]: {
      width: spacing(5),
      height: spacing(5),
    },
  },

  previewImage: {
    height: 60,
    [breakpoints.down('sm')]: {
      height: 40,
    },
  },

  priceStyle: {
    fontWeight: 700,
  },

  titleItem: {
    fontWeight: 700,
    lineHeight: 1,
    [breakpoints.down('sm')]: {
      fontSize: '0.8em',
    },
  },

  sizePrice: {
    [breakpoints.down('sm')]: {
      fontSize: '0.8em',
    },
  },
}));

export default useStyles;
