import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette }) => {
  const handleWidth = ({ stretched }) => {
    if (!stretched) return 'auto';
    if (stretched === true) return '100%';
    return stretched;
  };

  // const getHeight = ({ fat, dense }) => {
  //   if (fat) return spacing(7);
  //   if (dense) return spacing(4);
  //   return spacing(5);
  // };

  const getColorByFilled = (property) => ({ filled }) => {
    if (!filled) return palette.white[property];
    if (filled === true) return palette.secondary[property];
    return palette[filled][property];
  };

  const getPadding = ({ fitted, stretched, padding }) => {
    if (padding) return spacing(padding);
    if (fitted || stretched) return 22;
    return spacing(8);
  };

  const getOpacity = ({ hidden, fixOpacity }) => {
    if (hidden) return 0;
    if (fixOpacity) return 1;
    return 0.6;
  };

  return ({
    root: {
      width: handleWidth,
      // height: getHeight,
      border: ({ noBorder }) => (noBorder ? 0 : `2px solid ${palette.secondary.main}`),
      borderRadius: 999,
      paddingLeft: getPadding,
      paddingRight: getPadding,
      paddingTop: spacing(1.5),
      paddingBottom: spacing(1.5),
      fontSize: '17px',
      backgroundColor: getColorByFilled('main'),
      color: getColorByFilled('contrastText'),
      fontWeight: 900,
      letterSpacing: '0.06rem',
      transition: '200ms ease, opacity 150ms ease',
    },

    disabled: {
      opacity: getOpacity,
    },
  });
});

export default useStyles;
