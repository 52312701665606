import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dispatch } from 'use-bus';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import useResponsive from '../../hooks/useResponsive';
import { ReactComponent as TimesIcon } from '../../assets/icons/times.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/banner-info.svg';
import { ReactComponent as NewsletterIcon } from '../../assets/icons/banner-newsletter.svg';
import useStyles from './style';

const TopBanner = ({ onClose, bannerShown }) => {
  const { t } = useTranslation('header');
  const {
    topBannerContainer,
    container,
    closeContainer,
    infoIconStyle,
    timesIconStyle,
    gridContainer,
  } = useStyles({ bannerShown });
  const { isSmallView } = useResponsive();

  const handleClick = () => {
    dispatch({ type: '@@newsletterModal/open' });
  };

  const handleCloseClick = (event) => {
    event.stopPropagation();
    onClose(event);
  };

  return (
    <AppBar
      position="fixed"
      color="default"
      elevation={0}
      className={topBannerContainer}
    >
      <div className={container}>
        <Container style={{ height: 35 }}>
          <Grid
            container
            justify="center"
            alignItems="center"
            onClick={handleClick}
            className={gridContainer}
          >
            <Hidden smDown>
              <Grid item>
                <Grid container alignItems="center">
                  <NewsletterIcon />
                </Grid>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item>
                <Grid container alignItems="center">
                  <InfoIcon />
                </Grid>
              </Grid>
            </Hidden>
            <Grid item>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: t(`newsletter-banner${isSmallView ? '-mobile' : ''}`) }}
              />
            </Grid>
            <Hidden smDown>
              <Grid item>
                <Grid container alignItems="center">
                  <InfoIcon className={infoIconStyle} />
                </Grid>
              </Grid>
            </Hidden>
            {onClose && (
              <ButtonBase
                disableRipple
                onClick={handleCloseClick}
                className={closeContainer}
              >
                <TimesIcon className={timesIconStyle} height={15} />
              </ButtonBase>
            )}
          </Grid>
        </Container>
      </div>
    </AppBar>
  );
};

TopBanner.propTypes = {
  onClose: PropTypes.func,
  bannerShown: PropTypes.bool,

};

TopBanner.defaultProps = {
  onClose: null,
  bannerShown: false,
};

export default TopBanner;
