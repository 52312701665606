import React, { createContext, useContext } from 'react';
import { childrenPropType } from '../components/propTypes';
import useGetGameStats from '../graphql/hooks/gamification/useGetGameStats';

const GamificationContext = createContext({});

const GamificationProvider = ({ children }) => {
  const { data, loading } = useGetGameStats();

  return (
    <GamificationContext.Provider value={{ ...data, loading }}>
      {children}
    </GamificationContext.Provider>
  );
};

const useGamificationContext = () => useContext(GamificationContext);

GamificationProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export {
  GamificationContext,
  GamificationProvider,
  useGamificationContext,
};
