import aboutUs from './aboutUs.json';
import authentication from './authentication.json';
import cart from './cart.json';
import contacts from './contacts.json';
import common from './common.json';
import dashboard from './dashboard.json';
import dish from './dish.json';
import footer from './footer.json';
import gamification from './gamification.json';
import header from './header.json';
import home from './home.json';
import howItWorks from './howItWorks.json';
import ingredients from './ingredients.json';
import newsletter from './newsletter.json';
import order from './order.json';
import orderDishes from './orderDishes.json';
import plans from './plans.json';
import profile from './profile.json';
import recipe from './recipe.json';
import shoppingList from './shoppingList.json';
import staticPage from './staticPage.json';
import titles from './titles.json';
import validations from './validations.json';

const it = {
  aboutUs,
  authentication,
  cart,
  contacts,
  common,
  dashboard,
  dish,
  footer,
  gamification,
  header,
  home,
  howItWorks,
  ingredients,
  newsletter,
  order,
  orderDishes,
  plans,
  profile,
  recipe,
  shoppingList,
  staticPage,
  titles,
  validations,
};

export default it;
