import { useState } from 'react';

const useNotifications = () => {
  const [ignore, setIgnore] = useState(true);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [onClick, setOnClick] = useState(null);
  const [icon] = useState(process.env.REACT_APP_LOGO_URL);

  const options = {
    body,
    icon,
  };

  return {
    ignore,
    onClick,
    options,
    setBody,
    setIgnore,
    setOnClick,
    setTitle,
    title,
  };
};

export default useNotifications;
