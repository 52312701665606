import React, { useEffect, useRef } from 'react';
import useBus from 'use-bus';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import librawayV2Theme from '../../theme/v2';
import useModalOpen from '../../hooks/useModalOpen';
import useMouseLeaveWindow from '../../hooks/useMouseLeaveWindow';
import useResponsive from '../../hooks/useResponsive';
import { useAppContext } from '../../contexts/AppContext';
import { librawayStorage } from '../../libraries/helpers';
import { pushCustomEventToDataLayer } from '../../libraries/analyticsHelpers';
import Dialog from '../Dialog';
import MainContent from './MainContent';

const WelcomeModal = () => {
  const { isWithinWebview } = useAppContext();
  const { opened, closeModal, openModal } = useModalOpen(false);
  const mouseLeave = useMouseLeaveWindow();
  const { isSmallView } = useResponsive();
  const location = useLocation();
  const matchSigninRoute = useRouteMatch('/signin');
  const matchDashboardRoute = useRouteMatch('/dashboard');
  const actualLocation = useRef(null);

  const openable = !librawayStorage.get('welcomeModal')
    && !isWithinWebview
    && !matchSigninRoute
    && !matchDashboardRoute;

  useBus('@@newsletterModal/open', openModal);

  useEffect(() => {
    if (opened) {
      librawayStorage.set('welcomeModal', true);
      pushCustomEventToDataLayer('Newsletter', 'Popup View', location?.pathname);
    }
  }, [opened]);

  /**
   * Mobile device handler
   */
  useEffect(() => {
    if (
      isSmallView
      && openable
      && actualLocation.current !== null
      && actualLocation.current !== location?.pathname
    ) {
      openModal();
    } else {
      actualLocation.current = location.pathname;
    }
  }, [isSmallView, location, openable]);

  /**
   * Desktop device handler
   */
  useEffect(() => {
    if (!isSmallView && openable && mouseLeave) {
      openModal();
    }
  }, [isSmallView, mouseLeave, openable]);

  return (
    <ThemeProvider theme={librawayV2Theme}>
      <Dialog
        open={opened}
        onClose={closeModal}
        minWidth={600}
        noPadding
      >
        <MainContent />
      </Dialog>
    </ThemeProvider>
  );
};

export default WelcomeModal;
