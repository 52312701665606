import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import { childrenPropType } from '../propTypes';
import useStyles from './style';

const Button = ({
  children,
  className,
  dense,
  disabled,
  fat,
  filled,
  fitted,
  fixOpacity,
  hidden,
  noBorder,
  onClick,
  padding,
  style,
  stretched,
  type,
}) => (
  <ButtonBase
    disabled={disabled || hidden}
    onClick={onClick}
    classes={{
      ...useStyles({
        dense,
        fat,
        filled,
        fitted,
        fixOpacity,
        hidden,
        noBorder,
        padding,
        stretched,
      }),
    }}
    style={style}
    className={className}
    type={type}
  >
    {children}
  </ButtonBase>
);

Button.propTypes = {
  children: childrenPropType,
  className: PropTypes.string,
  dense: PropTypes.bool,
  disabled: PropTypes.bool,
  fat: PropTypes.bool,
  filled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  fitted: PropTypes.bool,
  fixOpacity: PropTypes.bool,
  hidden: PropTypes.bool,
  noBorder: PropTypes.bool,
  onClick: PropTypes.func,
  padding: PropTypes.number,
  style: PropTypes.shape(),
  stretched: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

Button.defaultProps = {
  children: '',
  className: '',
  dense: false,
  disabled: false,
  fat: false,
  filled: false,
  fitted: false,
  fixOpacity: false,
  hidden: false,
  noBorder: false,
  onClick: () => {},
  padding: null,
  style: {},
  stretched: false,
  type: 'button',
};

export default Button;
