import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import useReadNotification from '../../graphql/hooks/notifications/useReadNotification';
import useStyles from './style';

const ReadAllButton = () => {
  const { t } = useTranslation();
  const { readAllContainer, readAllButton } = useStyles();
  const [readNotification] = useReadNotification();

  const handleClick = () => {
    readNotification({ variables: { } });
  };

  return (
    <div className={readAllContainer}>
      <ButtonBase className={readAllButton} onClick={handleClick}>
        <Typography variant="caption">
          {t('read-all-notifications')}
        </Typography>
      </ButtonBase>
    </div>
  );
};

export default ReadAllButton;
