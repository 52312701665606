import React, { useEffect, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { dispatch } from 'use-bus';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import { childrenPropType } from '../propTypes';
import useResponsive from '../../hooks/useResponsive';
import Close from './Close';
import useStyles from './style';

const Dialog = ({
  children,
  className,
  closeButton,
  large,
  minWidth,
  noPadding,
  onClose,
  open,
  title,
}) => {
  const {
    container,
    titleContainer,
    content,
    scrollPaper,
  } = useStyles({ large, noPadding, minWidth });
  const { isSmallView } = useResponsive();

  useEffect(() => {
    if (isSmallView) {
      dispatch({ type: '@@rasa/display', payload: open });
    }
  }, [open, isSmallView]);

  const renderCloseButton = () => {
    if (!closeButton) return null;
    if (closeButton === true) return <Close onClick={onClose} />;
    const CloseButtonComponent = closeButton;
    return <CloseButtonComponent onClick={onClose} />;
  };

  /* eslint-disable react/jsx-props-no-spreading */
  const Transition = useMemo(() => forwardRef((props, ref) => (
    isSmallView ? (
      <Slide direction="up" ref={ref} {...props} />
    ) : (
      <Fade ref={ref} {...props} />
    )
  )), [isSmallView]);
  /* eslint-enable react/jsx-props-no-spreading */

  return (
    <MuiDialog
      TransitionComponent={Transition}
      classes={{ paper: classNames(container, className), scrollPaper }}
      onClose={onClose}
      open={open}
    >
      <DialogTitle disableTypography className={titleContainer}>
        <Typography variant="h3" align="center">
          {title}
        </Typography>
        {renderCloseButton()}
      </DialogTitle>
      <DialogContent className={content}>
        {children}
      </DialogContent>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  children: childrenPropType.isRequired,
  className: PropTypes.string,
  large: PropTypes.bool,
  minWidth: PropTypes.number,
  noPadding: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  closeButton: PropTypes.oneOfType([
    PropTypes.bool,
    childrenPropType,
  ]),
  title: PropTypes.string,
};

Dialog.defaultProps = {
  className: '',
  large: false,
  minWidth: null,
  noPadding: false,
  onClose: () => {},
  open: false,
  closeButton: true,
  title: null,
};

export default Dialog;
