import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette: { common, primary, secondary }, spacing }) => {
  const color = ({ boxCompleted }) => (boxCompleted ? common.white : common.black);
  const backgroundColor = ({ boxCompleted }) => (boxCompleted ? primary.mainDark : secondary.main);

  return ({
    pageContainer: {
      overflowX: 'hidden',
      transition: 'margin-top 150ms ease',
      marginTop: ({ bannerShown }) => (bannerShown ? 35 : 0),
    },

    mainContainer: {
      position: 'sticky',
      bottom: 0,
      width: '100%',
    },

    cartContainer: {
      color,
      backgroundColor,
      transition: 'background-color 200ms ease',
      minHeight: spacing(10),
    },

    collapseContainer: {
      backgroundColor: common.white,
      borderRadius: '20px 20px 0 0',
      boxShadow: 'rgba(99, 99, 99, 0.8) 0px 2px 8px 0px',
    },

    collapseInnerContainer: {
      padding: spacing(2, 0),
    },

    mobileContainer: {
      width: '100%',
      color: common.white,
      backgroundColor,
      transition: 'background-color 200ms ease',
      padding: spacing(2, 0),
      cursor: 'pointer',
    },

    cartIcon: {
      '& .cart-main, & .main-boxBlank': {
        fill: ({ boxCompleted }) => (boxCompleted ? secondary.main : common.white),
      },
    },
  });
});

export default useStyles;
