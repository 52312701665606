import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
  spacing,
  palette,
}) => {
  const {
    primary,
    white,
    black,
    red,
  } = palette;

  return ({
    labelStyle: {
      paddingBottom: spacing(),
    },

    root: {
      width: ({ fullWidth }) => (fullWidth ? '100%' : 'fit-content'),
      minHeight: ({ fat }) => (fat ? spacing(9) : 0),
      height: ({ multiline, dense }) => {
        if (multiline) return spacing(21);
        if (dense) return spacing(4);
        return 'auto';
      },
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: ({ borderColor, hasError, readOnly }) => {
        if (hasError) return red.main;
        if (readOnly) return 'transparent';
        return get(palette, borderColor);
      },
      borderRadius: ({ square, multiline }) => (square || multiline ? 20 : 999),
      padding: ({ dense }) => (dense ? spacing(0, 2) : '5px 20px'),
      fontSize: '1rem',
      backgroundColor: white.main,
      color: white.contrastText,
      fontWeight: 900,
      letterSpacing: '0.06rem',
      transition: '300ms ease',
      opacity: ({ disabled }) => (disabled ? 0.5 : 1),
    },

    input: {
      height: ({ multiline }) => (multiline ? '90%' : 'auto'),
      transition: 'color 300ms ease',
    },

    timesIcon: {
      transition: 'opacity 120ms ease',
      opacity: ({ voidInput }) => (voidInput ? 1 : 0),
      '& path, line': {
        stroke: black.light,
      },
    },

    checkIcon: {
      color: primary.dark,
    },

    errorIcon: {
      color: red.main,
    },
  });
});

export default useStyles;
