import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  paper: {
    width: '100vw',
    backgroundColor: primary.light,
  },
}));

export default useStyles;
