import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import useReactHookForm from '../../hooks/useReactHookForm';
import useResponsive from '../../hooks/useResponsive';
import librawayThemeV2 from '../../theme/v2';

const fields = ['privacy', 'marketing'];

const PrivacyCheckbox = ({ register, disabled, requiredFields }) => {
  const { t } = useTranslation('common');
  const { registers: { getRequired } } = useReactHookForm();
  const { isSmallView } = useResponsive();

  const getPreLabel = (name, index) => {
    if (requiredFields[index]) return `[<b>${t('privacy-mandatory-1')}</b>] `;
    if (name === 'marketing') return `[<b>${t('privacy-mandatory-2')}</b>] `;
    return '';
  };

  return (
    <ThemeProvider theme={librawayThemeV2}>
      <Grid container direction="column" spacing={2}>
        {fields.map((name, index) => (
          <Grid item key={name}>
            <Grid container justify="flex-start" alignItems="flex-start">
              <Grid item container xs={1} justify="center">
                <Checkbox
                  inputProps={{ name }}
                  inputRef={register(requiredFields[index] && getRequired(name))}
                  required={requiredFields[index]}
                  disabled={disabled}
                  color="primary"
                />
              </Grid>
              <Grid item xs={11} style={{ paddingLeft: isSmallView ? 8 : 0, paddingTop: 8 }}>
                <Typography variant="caption" style={{ lineHeight: '16px' }}>
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: `${getPreLabel(name, index)}${t(name)}` }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </ThemeProvider>
  );
};

PrivacyCheckbox.propTypes = {
  register: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  requiredFields: PropTypes.arrayOf(PropTypes.bool),
};

PrivacyCheckbox.defaultProps = {
  disabled: false,
  requiredFields: [true, false],
};

export default PrivacyCheckbox;
